// Import the resources
import React, {useState, useEffect, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import '../../../styles/policy_style.css'
import TablePolicy from '../../pure/tablePolicy';
import ChartPolicy from '../../pure/chartPolicy';
import ModalPolicy from '../../pure/modals/modalPolicy';
import Papa from 'papaparse';
import Swal from 'sweetalert2';
import warning from '../../../images/Warning.png'
import Loading from '../../pure/loading';
import { getPolicy, getPlannersPlants, getDemands, postPolicy, postDemands, postApprove } from '../../../models/policy_data';
import { PlantsContext } from '../../../context/PlantsContext';
import { CountryComponentContext } from '../../../context/CountryComponentContext';
import { ZoneContext } from '../../../context/ZoneContext';
import { addSummaries } from '../../../redux/summarySlice';
import { addFilterPlanners, addFilterPlants, addPolicies, addMaterial, addPlanners, addMaterialsTypes, addDemands, addServiceLevel, addMonthsMaximum, addmonthsHistory, addLeadtime, addExclude } from '../../../redux/policySlice';
import { useSelector, useDispatch } from 'react-redux';
import { UserContext } from '../../../context/UserContext';
import { RoleContext } from '../../../context/RoleContext';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { removeAllCookies } from '../../../models/generics';

/**
 * @component
 * @description This component show the policy management view
*/
const PolicyComponent = () => {
    /**
     * @hook
     * @type {function}
     * @description Hook to make the translator using the en.js and es.js file
    */
    const { t } = useTranslation();

    /**
     * @hook
     * @name dispatch
     * @type {function}
     * @description It provides the ability save and get data in the redux store
    */
    const dispatch = useDispatch()

    /**
     * @hook
     * @name policy
     * @type {function}
     * @description It provides the global data in the redux store
    */
    const policy = useSelector((state) => state.policy)

    /**
     * @context
     * @description Access to get the global state of the PlantsContext
    */
    const {plants} = useContext(PlantsContext)

    /**
     * @context
     * @description Access to get the global state of the UserContext
    */
    const {user} = useContext(UserContext)

    /**
     * @context
     * @description Access to get the global state of the RoleContext
    */
    const {role} = useContext(RoleContext)

    /**
     * @context
     * @description Access to get the global state of the CountryComponentContext
    */
    const {country} = useContext(CountryComponentContext)

    /**
     * @context
     * @description Access to get the global state of the ZoneContext
    */
    const {zone} = useContext(ZoneContext)

    /**
     * @const
     * @description Const to save the text to show while the view is loading
    */
    const loadText = t("policyManagement.cargaText")

    /**
     * @hook
     * @name navigate
     * @type {function}
     * @description It provides the ability to the app with the country selected
    */
    const navigate = useNavigate()

    /**
     * @state
     * @type {boolean}
     * @default false
     * @description Local State to show the logoCarga
    */
    const [loading, setLoading] = useState(true);

    /**
     * @state
     * @type {array}
     * @default []
     * @description Local State to save the planners obtained of the endpoint
    */
    const [planners, setPlanners] = useState([]);

    /**
     * @state
     * @type {array}
     * @default []
     * @description Local States to save the policy and demands data
    */
    const [newPolicyDataTable, setNewPolicyDataTable] = useState([]);
    const [newDemandsDataTable, setNewDemandsDataTable] = useState([]);

    /**
     * @state
     * @type {array}
     * @default []
     * @description Local State to save the material types from the endpoint
    */
    const [materialTypes, setMaterialTypes] = useState([]);

    /**
     * @state
     * @type {number}
     * @default 0
     * @description Local States to update the calculations to show in the first barcharts
    */
    const [reorderPointOne, setReorderPointOne] = useState(0);
    const [reorderPointTwo, setReorderPointTwo] = useState(0);
    const [stockMaxOne, setStockMaxOne] = useState(0);
    const [stockMaxTwo, setStockMaxTwo] = useState(0);

    /**
     * @state
     * @type {number}
     * @default 0
     * @description Local States to update the avareage calculations to show in the last barchart
    */
    const [totalOne, setTotalOne] = useState(0);
    const [totalTwo, setTotalTwo] = useState(0);

    /**
     * @state
     * @type {string}
     * @default 0
     * @description Local State to save the material wrote in the modal
    */
    const [wantedMaterial, setWantedMaterial] = useState("");

    /**
     * @state
     * @type {string}
     * @default 0
     * @description Local State to save the material wrote in the input field in the table
    */
    const [fingeredMaterial, setFingeredMaterial] = useState("");

    /**
     * @state
     * @type {array}
     * @default []
     * @description Local State to save the selected default planners in the modal
    */
    const [plannersSelected, setPlannersSelected] = useState([]);

    /**
     * @state
     * @type {array}
     * @default []
     * @description Local State to save the selected default planners in the modal
    */
    const [plantsSelected, setPlantsSelected] = useState([]);

    /**
     * @state
     * @type {array}
     * @default []
     * @description Local State to save the conditions to show buttons in plants filters row
    */
    const [buttonsArmed, setButtonsArmed] = useState([]);

    /**
     * @state
     * @type {array}
     * @default []
     * @description Local State to save the selected default turnover in the modal
    */
    const [levelsSelected, setLevelsSelected] = useState([]);

    /**
     * @state
     * @type {number}
     * @default 0
     * @description Local State to save the "Service level" selected in the modal
    */
    const [serviceLevel, setServiceLevel] = useState(0);

    /**
     * @state
     * @type {array}
     * @default []
     * @description Local State to save the selected default material types in the modal
    */
    const [materialsSelected, setMaterialsSelected] = useState([]);

    /**
     * @state
     * @type {number}
     * @default 0
     * @description Local State to save the "Months for maximum stock" selected in the modal
    */
    const [monthsMaximunStock, setMonthsMaximunStock] = useState(0);

    /**
     * @state
     * @type {boolean}
     * @default true
     * @description Local State to save the value on the checkbox for "Exclude atypical demands"
    */
    const [checkAtypical, setCheckAtypical] = useState(true);

    /**
     * @state
     * @type {string}
     * @default ""
     * @description Local State to save the value of "Months of history" selected in the modal
    */
    const [monthsHistory, setMonthsHistory] = useState("");

    /**
     * @state
     * @type {string}
     * @default ""
     * @description Local State to save the value of "Leadtime calculation" selected in the modal
    */
    const [leadtime, setLeadtime] = useState("");

    /**
     * @state
     * @type {array}
     * @default []
     * @description Local States to save the Planners and Plants used in the filter on the superior bar
    */
    const [filterPlanners, setFilterPlanners] = useState([]);
    const [filterPlants, setFilterPlants] = useState([]);

    /**
     * @state
     * @type {array}
     * @default []
     * @description Local State to save the plants which is possible to found data according with the 
     * planner selected and the material wrote.
    */
    const [possiblePlants, setPossiblePlants] = useState([]);

    /**
     * @state
     * @type {string}
     * @default policy
     * @description Local State to know the table showed in the moment
    */
    const [activeTable, setActiveTable] = useState("policy");

    const [restartScroll, setRestartScroll] = useState(false);

    /**
     * @function
     * @name calculate
     * @description Function to calculate the value of the states:
     * recorderPointUno, recorderPointDos, stockMaximoUno and stockMaximoDos
     * @param {Object} data - Policies array
    */
    function calculate(data) {
        let firstSum = 0
        let secondSum = 0
        let thirdSum = 0
        let fourthSum = 0
        if (data.length > 0) {
            data.forEach((item) => {
                const rpValue = item.rp
                const rpNewValue = item.rp_new
                const smValue = item.sm
                const smNewValue = item.sm_new
                const lastPriceUsdValue = item.last_price_usd
                if (!isNaN(rpValue) && !isNaN(lastPriceUsdValue)) {
                    firstSum += item.approved ? rpValue * lastPriceUsdValue : 0;
                    secondSum += item.approved ? rpNewValue * lastPriceUsdValue : 0;
                    thirdSum += item.approved ? smValue * lastPriceUsdValue : 0;
                    fourthSum += item.approved ? smNewValue * lastPriceUsdValue : 0;
                }
            });   
        } else {
            firstSum =0;
            secondSum =0;
            thirdSum =0;
            fourthSum =0;
        }
        return {
            "firstResult": firstSum,
            "secondResult": secondSum,
            "thirdResult": thirdSum,
            "fourthResult": fourthSum
        }
    }

    /**
     * @function
     * @name average
     * @description Function to calculate the value of the states: totalOne y totalTwo
     * @param {number} valueOne - reorderPointOne or reorderPointTwo
     * @param {number} valueTwo - stockMaxOne or stockMaxTwo
    */
    function average(valueOne, valueTwo) {
        let average = 0
        average = (valueOne + valueTwo)/2
        return average
    }

    /**
     * @effect
     * @description This effect is executed when the data in policy store is updated. 
     * If policy.policies quantity is greater than 0 updates the states to have data in the module
    */
    useEffect(() => {
        const policyPlannersArray = [...new Set(policy.policies.map(item => item.planner))];
        const policyPlantsArray = [...new Set(policy.policies.map(item => item.plant))];
        const demandsPlannersArray = [...new Set(policy.demands.map(item => item.planner))];
        const demandsPlantsArray = [...new Set(policy.demands.map(item => item.plant))];
        const finalPlannersArray = [...new Set([...policyPlannersArray, ...demandsPlannersArray])]
        const finalPlantsArray = [...new Set([...policyPlantsArray, ...demandsPlantsArray])]
        const policyMaterialsArray = [...new Set(policy.policies.map(item => item.material_type))];
        const demandsMaterialsArray = [...new Set(policy.demands.map(item => item.material_type))];
        const finalMaterialsArray = [...new Set([...policyMaterialsArray, ...demandsMaterialsArray])]
        const policyLevelsArray = [...new Set(policy.policies.map(item => item.turnover.toString()))];
        const demandsLevelsArray = [...new Set(policy.demands.map(item => item.turnover.toString()))];
        const finalLevelsArray = [...new Set([...policyLevelsArray, ...demandsLevelsArray])]
        if (newPolicyDataTable.length < 1) {
            setNewPolicyDataTable(policy.policies)
        }
        setFilterPlanners(policy.filterPlanners)
        setFilterPlants(policy.filterPlants)
        setPlannersSelected(finalPlannersArray)
        setPlantsSelected(finalPlantsArray)
        if (newDemandsDataTable.length < 1) {
            const demandsWithId = policy.demands.map((obj, index) => {
                return {...obj, id: index + 1};
            });
            setNewDemandsDataTable(demandsWithId)
        }
        setWantedMaterial(typeof policy.material === 'string' ? policy.material : "")
        setPlanners(policy.planners);
        setMaterialTypes(policy.materialsTypes)
        setServiceLevel(policy.serviceLevel)
        setMonthsMaximunStock(policy.monthsMaximum)
        setMonthsHistory(policy.monthsHistory)
        setLeadtime(policy.leadtime)
        setCheckAtypical(policy.exclude)
        setLevelsSelected(finalLevelsArray)
        setMaterialsSelected(finalMaterialsArray)
        if (policy.policies.length > 0 || policy.demands.length > 0) {
            setLoading(false)
        }
    }, [policy]);
    /**
     * @effect
     * @description This effect is executed when the component is opened. 
     * If policy.policies quantity is less than 1, execute the get methods getPlannersPlants, getPolicy 
     * and getDemands
     * Which data execute the reducers addPolicies, addPlanners and addDemands
    */
    useEffect(() => {
        if (policy.policies.length < 1 && policy.demands.length < 1) {
            const promisePlannerPlant = getPlannersPlants(country, zone)
            const promisePolicy = getPolicy(country, plants[0], zone)
            const promiseDemands = getDemands(country, plants[0], zone)
            Promise.all([promisePlannerPlant, promisePolicy, promiseDemands])
            .then(([responsePlannerPlant, responsePolicy, responseDemands]) => {
                if (responsePolicy.responseData?.length > 0) {
                    const checkedData = responsePolicy.responseData.map(item => (
                        { ...item,
                            history_months: monthsHistory,
                            exclude_atypical: checkAtypical,
                            leadtime_calc: leadtime,
                            stock_cycle_months: monthsMaximunStock,
                            mbew_unit_price_usd: 0
                        }
                    ));
                    dispatch(addPolicies(checkedData))
                } else {
                    dispatch(addPolicies([]))
                }
 
 
                if (responsePolicy.responseData?.length > 0) {
                    dispatch(addFilterPlanners([responsePolicy.responseData[0].planner]))
                    dispatch(addFilterPlants([responsePolicy.responseData[0].plant]))
                } else if (responseDemands.responseData?.length > 0) {
                    dispatch(addFilterPlanners([responseDemands.responseData[0].planner]))
                    dispatch(addFilterPlants([responseDemands.responseData[0].plant]))
                } else {
                    dispatch(addFilterPlanners([]))
                    dispatch(addFilterPlants([]))
                }
 
                if (responsePolicy.responseData?.length < 1 && responseDemands.responseData?.length < 1) {
                    Swal.fire({
                        icon: 'info',
                        title: t("policyManagement.initialEmptyTitle"),
                        text: t("policyManagement.initialEmptyBoth"),
                        confirmButtonColor: '#FFC629',
                        confirmButtonText: "Ok",
                        allowOutsideClick: false
                    })
                } else if (responsePolicy.responseData?.length < 1) {
                    Swal.fire({
                        icon: 'info',
                        title: t("policyManagement.initialEmptyTitle"),
                        text: t("policyManagement.initialEmptyPolicy"),
                        confirmButtonColor: '#FFC629',
                        confirmButtonText: "Ok",
                        allowOutsideClick: false
                    })
                } else if (responseDemands.responseData?.length < 1) {
                    Swal.fire({
                        icon: 'info',
                        title: t("policyManagement.initialEmptyTitle"),
                        text: t("policyManagement.initialEmptyDemands"),
                        confirmButtonColor: '#FFC629',
                        confirmButtonText: "Ok",
                        allowOutsideClick: false
                    })
                }
               
                dispatch(addPlanners(responsePlannerPlant.planners))
                dispatch(addMaterialsTypes(responsePlannerPlant.materials))
                dispatch(addDemands(responseDemands.responseData))
                setLoading(false)
            }).catch(error => {
                if (error.response.status === 401) {
                    Swal.fire({
                        icon: 'error',
                        title: t("finishedSessionTitle"),
                        text: t("finishedSessionText"),
                        confirmButtonColor: '#FFC629',
                        confirmButtonText: "LogIn",
                        allowOutsideClick: false
                    }).then(() => {
                        removeAllCookies()
                        navigate("/")
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: '¡Oops!',
                        text: t("ApiFail"),
                        confirmButtonColor: '#FFC629'
                    })
                }
                setLoading(false)
            });
        }
    }, []);

    /**
     * @effect
     * @description This effect is executed when the states recorderPointUno and stockMaximoUno are updated. 
     * Update the totalUno state with the "promedio" value
    */
    useEffect(() => {
        setTotalOne(average(reorderPointOne, stockMaxOne))
    }, [reorderPointOne, stockMaxOne]);

    /**
     * @effect
     * @description This effect is executed when the states recorderPointDos and stockMaximoDos are updated. 
     * Update the totalUno state with the "promedio" value
    */
    useEffect(() => {
        setTotalTwo(average(reorderPointTwo, stockMaxTwo))
    }, [reorderPointTwo, stockMaxTwo]);

    /**
     * @state
     * @type {array}
     * @default []
     * @description Local States to show the filtered data of the states newDataTable and 
     * newDataTableDemands according with plannersFiltro and plantsFiltro states
    */
    const [filteredDataPolicy, setFilteredDataPolicy] = useState([]);
    const [filteredDataDemands, setFilteredDataDemands] = useState([]);

    /**
     * @function
     * @name filterTableByPlanners
     * @description Function to update the state plannersFiltro, if the materialModal is empty
     * the add or delete elements in the array, contrary case only update with the value sent
     * @param {string} value - Planner selected in the superior bar
    */
    function filterTableByPlanners(value) {
        if (wantedMaterial !== "") {
            if (filterPlanners.includes(value)) {
                dispatch(addFilterPlanners(filterPlanners.filter(item => item !== value)))
            }else{
                dispatch(addFilterPlanners([...filterPlanners, value]))
            }
        }else{
            dispatch(addFilterPlanners([value]))
            setRestartScroll(true)
        }
    }

    /**
     * @function
     * @name filterTableByPlants
     * @description Function to update the state plantsFiltro, if the materialModal is empty
     * the add or delete elements in the array, contrary case only update with the value sent
     * @param {string} value - Plant selected in the superior bar
    */
    function filterTableByPlants(value) {
        if (wantedMaterial !== "") {
            if (filterPlants.includes(value)) {
                dispatch(addFilterPlants(filterPlants.filter(item => item !== value)))
            }else{
                dispatch(addFilterPlants([...filterPlants, value]))
            }
        }else{
            dispatch(addFilterPlants([value]))
            setRestartScroll(true)
        }
    }

    /**
     * @effect
     * @description This effect is executed when the states plannersFiltro, plantsFiltro and 
     * newDataTable, tablaActiva and materialDigitado are updated. 
     * Filter the newDataTable to update the filteredData to show in the policy table according with 
     * plannersFiltro and plantsFiltro.
     * Mark the plants on the superior bar according with plannersFiltro, tablaActiva and 
     * materialDigitado if this is different to empty
    */
    useEffect(() => {
        const policyDataFiltered = newPolicyDataTable.filter(item => filterPlanners.includes(item.planner) &&  filterPlants.includes(item.plant));
        setFilteredDataPolicy(policyDataFiltered);
        if (activeTable === "policy") {
            if (fingeredMaterial === "") {
                const possible = newPolicyDataTable.filter(item => filterPlanners.includes(item.planner) &&  plantsSelected.includes(item.plant));
                const possiblePlants = [...new Set(possible.map(item => item.plant))];
                setPossiblePlants(possiblePlants) 
            } else {
                const possible = newPolicyDataTable.filter(item => filterPlanners.includes(item.planner) &&  plantsSelected.includes(item.plant));
                const possibleText = possible.filter(item => item.material.includes(fingeredMaterial));
                const possiblePlants = [...new Set(possibleText.map(item => item.plant))];
                setPossiblePlants(possiblePlants) 
            }
        }
        const calculationResult = calculate(policyDataFiltered);
        const { firstResult, secondResult, thirdResult, fourthResult } = calculationResult;
        setReorderPointOne(firstResult);
        setReorderPointTwo(secondResult);
        setStockMaxOne(thirdResult);
        setStockMaxTwo(fourthResult);
    }, [filterPlanners, filterPlants, newPolicyDataTable, activeTable, fingeredMaterial]);

    /**
     * @effect
     * @description This effect is executed when the states plannersFiltro, plantsFiltro, 
     * newDataTable, tablaActiva and materialDigitado are updated. 
     * Filter the newDataTableDemands to update the filteredDataDemands to show in the demands 
     * table according with plannersFiltro and plantsFiltro.
     * Mark the plants on the superior bar according with plannersFiltro, tablaActiva and 
     * materialDigitado if this is different to empty
    */
    useEffect(() => {
        const demandsDataFiltered = newDemandsDataTable.filter(item => filterPlanners.includes(item.planner) &&  filterPlants.includes(item.plant));
        setFilteredDataDemands(demandsDataFiltered);
        if (activeTable === "demands") {
            if (fingeredMaterial === "") {
                const possible = newDemandsDataTable.filter(item => filterPlanners.includes(item.planner) &&  plantsSelected.includes(item.plant));
                const possiblePlants = [...new Set(possible.map(item => item.plant))];
                setPossiblePlants(possiblePlants) 
            } else {
                const possible = newDemandsDataTable.filter(item => filterPlanners.includes(item.planner) &&  plantsSelected.includes(item.plant));
                const possibleText = possible.filter(item => item.material.includes(fingeredMaterial));
                const possiblePlants = [...new Set(possibleText.map(item => item.plant))];
                setPossiblePlants(possiblePlants) 
            }
        }
    }, [filterPlanners, filterPlants, newDemandsDataTable, activeTable, fingeredMaterial]);


    /**
     * @state
     * @type {boolean}
     * @default false
     * @description Local state to show modal to make the POST method
    */
    const [modal, setModal] = useState(false);
    
    /**
     * @function
     * @name openModal
     * @description Function to update the value of the "modal" state
    */
    const openModal = () => setModal(!modal);

    /**
     * @state
     * @type {string}
     * @default ""
     * @description Local states to save the Material specified in the modal
    */
    const [materialModal, setMaterialModal] = useState("");

    /**
     * @state
     * @type {array}
     * @default []
     * @description Local states to save Planners, Plants, Turnover and Material Types selected in the modal
    */
    const [plannersModal, setPlannersModal] = useState([]);
    const [plantsModal, setPlantsModal] = useState([]);
    const [levelsModal, setLevelsModal] = useState([]);
    const [materialTypesModal, setMaterialTypesModal] = useState([]);

    /**
     * @state
     * @type {number}
     * @default 0
     * @description Local states to save Service level and Months for maximum stock selected in the modal
    */
    const [serviceLevelModal, setServiceLevelModal] = useState(0);
    const [monthsMaximumStockModal, setMonthsMaximumStockModal] = useState(0);

    /**
     * @state
     * @type {string}
     * @default ""
     * @description Local states to save Months of history and Leadtime calculation selected in the modal
    */
    const [monthsHistoryModal, setMonthsHistoryModal] = useState("");
    const [optionModal, setOptionModal] = useState("");

    /**
     * @state
     * @type {boolean}
     * @default false
     * @description Local states to save Exclude atypical demands value in the modal
    */
    const [checkModal, setCheckModal] = useState(false);

    /**
     * @functions
     * @name {sendMaterial, sendNewPlanners, sendNewPlants, sendNewLevels, sendServiceLevel, sendMonthsMaximumStock, 
     * sendMonthsHistory, sendOption, sendCheck, sendMaterialsType}
     * @description Function to update the states: materialModal, plannersModal, plantsModal, levelsModal, serviceLevelModal,
     * monthsMaximumStockModal, monthsHistoryModal, optionModal, checkModal and materialTypeModal
     * @param data - Respective value from the modal
    */
    const sendMaterial = (data) => {setMaterialModal(data)}
    const sendNewPlanners = (data) => {setPlannersModal(data)}
    const sendNewPlants = (data) => {setPlantsModal(data)}
    const sendNewLevels = (data) => {setLevelsModal(data)}
    const sendServiceLevel = (data) => {setServiceLevelModal(data)}
    const sendMonthsMaximumStock = (data) => {setMonthsMaximumStockModal(data)}
    const sendMonthsHistory = (data) => {setMonthsHistoryModal(data)}
    const sendOption = (data) => {setOptionModal(data)}
    const sendCheck = (data) => {setCheckModal(data)}
    const sendMaterialsType = (data) => {setMaterialTypesModal(data)}

    /**
     * @function
     * @name updateDataView
     * @description Function to execute the methods postPolicy and postDemands with the modal data 
     * and execute the reducers addPolicies, addDemands, addServiceLevel, addMonthsMaximum, 
     * addmonthsHistory, addLeadtime and addExclude
    */
    function updateDataView() {
        setModal(!modal)
        setNewPolicyDataTable([])
        setNewDemandsDataTable([])
        setLoading(true)

        const filtroModalPolicy = {
            "planners": plannersModal,
            "plants": plantsModal,
            "turnover": levelsModal,
            "service_level": serviceLevelModal,
            "months_max_stock": monthsMaximumStockModal,
            "months_history": monthsHistoryModal,
            "leadtime_calculation": optionModal,
            "exclude_atypical_demands": checkModal,
            "standard_deviation_lead_time": false,
            "material": materialModal !== "" ? parseInt(materialModal) : 0,
            "material_type": materialTypesModal
        }

        const filtroModalDemands = {
            "planners": plannersModal,
            "plants": plantsModal,
            "turnover": levelsModal,
            "months_history": monthsHistoryModal,
            "material": materialModal !== "" ? parseInt(materialModal) : 0,
            "material_type": materialTypesModal
        }

        const promisePolicy = postPolicy(filtroModalPolicy, country, zone)
        const promiseDemands = postDemands(filtroModalDemands, country, zone)
        Promise.all([promisePolicy, promiseDemands]).then(([responsePolicy, responseDemands]) => {
            const policyData = responsePolicy.data;
            const demandsData = responseDemands.data;

            if (policyData.length > 0) {
                processPolicyData(policyData);
            }

            if (demandsData.length > 0) {
                dispatch(addDemands(demandsData));
            }

            if (policyData.length > 0 || demandsData.length > 0) {
                dispatch(addMaterial(materialModal));
                processActiveTable(activeTable, policyData, demandsData);
            }

            dispatch(addServiceLevel(serviceLevelModal));
            dispatch(addMonthsMaximum(monthsMaximumStockModal));
            dispatch(addmonthsHistory(monthsHistoryModal));
            dispatch(addLeadtime(optionModal));
            dispatch(addExclude(checkModal));

            handleEmptyResponses(policyData, demandsData, materialModal);
            setLoading(false)
        }).catch(error => {
            if (error.response.status === 401) {
                Swal.fire({
                    icon: 'error',
                    title: t("finishedSessionTitle"),
                    text: t("finishedSessionText"),
                    confirmButtonColor: '#FFC629',
                    confirmButtonText: "LogIn",
                    allowOutsideClick: false
                }).then(() => {
                    removeAllCookies()
                    navigate("/")
                })
            } else {
                Swal.fire({
                    icon: 'error',
                    title: '¡Oops!',
                    confirmButtonColor: '#FFC629',
                    text: t("ApiFail")
                })
            }
            setLoading(false)
        });
    }

    /**
     * @function
     * @name processPolicyData
     * @description Function to save the data for the policy data using the addPolicies reducers
     * @param data - Policy data from the endpoint
    */
    function processPolicyData(data) {
        const checkedPolicyData = data.map(item => (
            { ...item,
                history_months: monthsHistory, 
                exclude_atypical: checkAtypical,
                leadtime_calc: leadtime,
                stock_cycle_months: monthsMaximunStock,
                mbew_unit_price_usd: 0
            }
        ));
        dispatch(addPolicies(checkedPolicyData));
    }

    /**
     * @function
     * @name processActiveTable
     * @description Fuction to define the inital filter in planners or plants for the superior bar according to 
     * the tabla shown
     * @param activeTable - Table active
     * @param policyData - Data of the policy table
     * @param demandsData - Data of the demand table
    */
    function processActiveTable(activeTable, policyData, demandsData) {
        const activeData = activeTable === "policy" ? policyData : demandsData;
        if (activeData.length > 0) {
            dispatch(addFilterPlanners([activeData[0].planner]));
            dispatch(addFilterPlants([activeData[0].plant]));
        }
    }

    /**
     * @function
     * @name handleEmptyResponses
     * @description Function to control the alerts to show if any data for the table would be empty
     * @param policyData - Data of the policy table
     * @param demandsData - Data of the demand table
     * @param materialModal - Material wrote in the modal
    */
    function handleEmptyResponses(policyData, demandsData, materialModal) {
        const emptyPolicy = policyData.length < 1;
        const emptyDemands = demandsData.length < 1;
        const materialModalEmpty = materialModal === "";
    
        if (emptyPolicy && emptyDemands) {
            const textKey = materialModalEmpty ? t("policyManagement.filtersFail") : t("policyManagement.materialFail");
            Swal.fire({ icon: 'error', title: '¡Oops!', text: t(textKey), confirmButtonColor: '#FFC629', });
        } else if (emptyPolicy) {
            const textKey = materialModalEmpty ? t("policyManagement.filtersPolicyFail") : t("policyManagement.materialPolicyFail");
            Swal.fire({ icon: 'info', title: t("policyManagement.warning"), text: t(textKey), confirmButtonColor: '#FFC629', });
        } else if (emptyDemands) {
            const textKey = materialModalEmpty ? t("policyManagement.filtersDemandsFail") : t("policyManagement.materialDemandsFail");
            Swal.fire({ icon: 'info', title: t("policyManagement.warning"), text: t(textKey), confirmButtonColor: '#FFC629', });
        }
    }

    /**
     * @function
     * @name exportToCSV
     * @description Function to clean the policy table data and export it into CSV for the user
     * @param data - Data to export to CSV
     * @param filename - Name of the CSV file
    */
    function exportToCSV(data, filename) {
        function removeHtmlTags(str) {
            return str.replace(/<[^>]*>?/gm, '');
        }
        const cleanedData = data.map(item => {
            const cleanedItem = {};
            for (let key in item) {
                if (typeof item[key] === 'string') {
                    cleanedItem[key] = removeHtmlTags(item[key]); // Remover etiquetas HTML
                } else {
                    cleanedItem[key] = item[key];
                }
            }
            return cleanedItem;
        });
        const now = new Date()
        const formatter = new Intl.DateTimeFormat('en', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(now).split('/').reverse().join('-');
        const convertedData = cleanedData.map((item) => {
            const copy = {...item};
            copy.rev_date = formatter;
            copy.user = user
            copy.approved = copy.approved ? "yes" : "no"
            copy.exclude_atypical = copy.exclude_atypical ? "yes" : "no";
            if ("siHay" in copy) {
                delete copy.siHay;
            }
            return copy;
        });
        const csv = Papa.unparse(convertedData);
        const csvData = new Blob([csv], { type: 'text/csv' });
        const csvUrl = URL.createObjectURL(csvData);
        const a = document.createElement('a');
        a.href = csvUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    /**
     * @function
     * @name convertJSONUser
     * @description Function to clean the newDataTable data to export it into CSV
    */
    function convertJSONUser() {
        const uniqueData = Array.from(new Set(newPolicyDataTable.map(item => item.id))).map(id => {
            return newPolicyDataTable.find(item => item.id === id);
        });
        return uniqueData
    }

    /**
     * @function
     * @name convertJSONApprove
     * @description Function to clean the newDataTable data to export it into CSV and send it to approve
    */
    function convertJSONApprove() {
        const now = new Date()
        const formatter = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(now).replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$1-$2');;
        const uniqueData = Array.from(new Set(newPolicyDataTable.map(item => item.id))).map(id => {
            return newPolicyDataTable.find(item => item.id === id);
        });

        const convertedData = uniqueData.map((item) => {
            const copy = {...item};
            copy.rev_date = formatter
            copy.user = user
            if ("siHay" in copy) {
                delete copy.siHay;
            }
            return copy;
        });
        return {"convertedData":convertedData, "uniqueData":uniqueData}
    }

    /**
     * @function
     * @name handleExportClick
     * @description Function to download the CSV file from the data returned in convertJSONUser
    */
    function handleExportClick() {
        const downloadData = convertJSONUser()
        const fileName = 'data_policy_management.csv';
        exportToCSV(downloadData, fileName);
    }

    /**
     * @function
     * @name handleExportApprove
     * @description Function to send the data to approve and download the CSV file from the uniqueData 
     * returned in convertJSONApprove
    */
    function handleExportApprove() {
        Swal.fire({
            html:`<div class="flex justify-center w-full"><img src="${warning}" alt="Tu imagen" class="imagen-alerta" /></div>`
            + '<br>' 
            + `<p class="mb-0 font-semibold">${t("policyManagement.firstPartApprove")}</p>` 
            + '<br>'        
            + `<p class="mb-0 font-semibold">${t("policyManagement.secondPartApprove")}</p>`,
            showCancelButton: true,
            confirmButtonColor: '#FFC629',
            cancelButtonColor: '#BFBFBF',
            confirmButtonText: t("policyManagement.confirmButton"),
            cancelButtonText: t("policyManagement.cancelButton"),
            footer: `<p class="mb-0 font-semibold text-neutral-300">${t("policyManagement.footer")}</p>`,
            customClass: {
                footer: 'mt-0 border-0 pt-1'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true)
                const {convertedData, uniqueData} = convertJSONApprove()
                const dataEnvio = {
                    "tableName":"Policy_Table",
                    "dataTable": convertedData
                }
                postApprove(dataEnvio, zone).then(response => {
                    const fileName = 'data_policy_management_approved.csv';
                    exportToCSV(uniqueData, fileName);
                    Swal.fire({
                        icon: 'success',
                        title: t("policyManagement.correctApproveTitle"),
                        confirmButtonColor: '#FFC629',
                        text: t("policyManagement.correctApproveText")
                    })
                    dispatch(addPolicies(uniqueData))
                    dispatch(addSummaries([]));
                    setLoading(false)
                }).catch(error => {
                    if (error.response.status === 401) {
                        Swal.fire({
                            icon: 'error',
                            title: t("finishedSessionTitle"),
                            text: t("finishedSessionText"),
                            confirmButtonColor: '#FFC629',
                            confirmButtonText: "LogIn",
                            allowOutsideClick: false
                        }).then(() => {
                            removeAllCookies()
                            navigate("/")
                        })
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: '¡Oops!',
                            confirmButtonColor: '#FFC629',
                            text: t("ApiFail")
                        })
                    }
                    setLoading(false)
                })
            }
        })
    }

    /**
     * @function
     * @name sendChecked
     * @description Function to update the newDataTable when change the approve key in a register
     * @param {object} data - Object with the new value in the key "approved"
    */
    const sendChecked = (data) => {
        const newArray = newPolicyDataTable.map((originalItem) => {
            if (originalItem.id === data.id) {
                return { ...originalItem, ...data }
            }
            return originalItem
        })

        setNewPolicyDataTable(newArray)
    }

    /**
     * @function
     * @name sendCheckeds
     * @description Function to update the newDataTable when change the approve key in all register
     * @param {array} data - Objects array
    */
    const sendCheckeds = (data) => {
        const updatedArray = newPolicyDataTable.map(item1 => {
            const matchingItem = data.find(item2 => item2.id === item1.id);
            if (matchingItem) {
              return matchingItem;
            }
            return item1;
        });
        setNewPolicyDataTable(updatedArray)
    }

    /**
     * @function
     * @name sendFilterText
     * @description Function to update the materialDigitado and know the first planner and plant which is 
     * possible found data with the material wrote.
     * @param {Object} data - Object JSON with the material and table showed
    */
    const sendTextFilter = (data) => {
        setFingeredMaterial(data.material)
        if (data.policy && data.material !== "") {
            const filteredData = newPolicyDataTable.filter(item =>
                item.material.includes(data.material)
            );
            if (filteredData.length > 0) {
                dispatch(addFilterPlanners([filteredData[0].planner]))
                dispatch(addFilterPlants([filteredData[0].plant]))
            } else {
                dispatch(addFilterPlanners([newPolicyDataTable[0].planner]))
                dispatch(addFilterPlants([newPolicyDataTable[0].plant]))
            }
        }

        if (data.demands && data.material !== "") {
            const filteredData = newDemandsDataTable.filter(item =>
                item.material.includes(data.material)
            );
            if (filteredData.length > 0) {
                dispatch(addFilterPlanners([filteredData[0].planner]))
                dispatch(addFilterPlants([filteredData[0].plant]))
            } else {
                dispatch(addFilterPlanners([newDemandsDataTable[0].planner]))
                dispatch(addFilterPlants([newDemandsDataTable[0].plant]))
            }
        }
    }

    /**
     * @function
     * @name sendActiva
     * @description Function to update the tablaActiva and know what table is showed.
     * @param {string} data - Table selected
    */
    const sendActiveTable = (data) => {
        setActiveTable(data)
    }

    const sendRestarScroll = (data) => {
        setRestartScroll(data)
    }

    const sendLoadDemandsModal = (data) => {
        setLoading(data)
    }

    /**
     * @effect
     * @description This effect is executed when plantsSelected, filterPlants, possiblePlants are changed,
     * define the styles for the buttons in the plants row according with the conditions
    */
    useEffect(() => {
        const newArray = plantsSelected.map((item, index) => {
            let buttonClass = ""
            if (filterPlants.includes(item)) {
                buttonClass = 'bg-black text-brand-300';
            } else if (possiblePlants.includes(item)) {
                buttonClass = 'bg-brand-200 text-neutral-500 hover:bg-brand-300';
            } else {
                buttonClass = 'bg-neutral-100 text-neutral-500 hover:bg-brand-200';
            }

            return {
                plant: item,
                buttonClass: buttonClass
            };
        })
        setButtonsArmed(newArray)
    }, [plantsSelected, filterPlants, possiblePlants]);

    return (
        <div className='wallpaper flex flex-col w-4.5/5 h-full p-1 gap-y-1'>
            {/* Modal to make the post method to have new data in both tables */}
            <Dialog open={modal} classes={{paper: "!max-w-4.25/5 !w-4.25/5 2xl:!max-w-3.5/5 2xl:!w-3.5/5 4xl:!max-w-1/2 4xl:!w-1/2 !h-full 2xl:!h-3/4 4xl:!h-1/2 wallpaper-modal !rounded-lg"}}>
                <DialogTitle className='flex justify-between w-full items-center !py-1'>
                    <div className='flex justify-start w-1/2'>
                        <p className='mb-0 font-semibold text-neutral-200'>{t("policyManagement.filterSelection")}</p>
                    </div>
                    <div className='flex justify-start w-2.25/5 ms-2'>
                        <p className='mb-0 font-semibold text-neutral-200'>{t("policyManagement.policyParameters")}</p>
                    </div>
                    <div className='flex justify-center w-0.25/5'>
                        <IconButton color="inherit" onClick={openModal} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent className='!p-2 !overflow-x-hidden'>
                    <ModalPolicy planners={planners} 
                        plants={plants}
                        wantedMaterial={wantedMaterial}
                        plannersSelected={plannersSelected}
                        plantsSelected={plantsSelected}
                        levelsSelected={levelsSelected}
                        materialTypes={materialTypes}
                        materialsSelected={materialsSelected}
                        serviceLevelDefault={serviceLevel}
                        monthsMaximunStock={monthsMaximunStock}
                        monthsHistory={monthsHistory}
                        checkAtypical={checkAtypical}
                        leadtime={leadtime}
                        sendMaterial={sendMaterial}
                        sendNewPlanners={sendNewPlanners}
                        sendNewPlants={sendNewPlants}
                        sendNewLevels={sendNewLevels}
                        sendMaterialsType={sendMaterialsType}
                        sendServiceLevel={sendServiceLevel}
                        sendMonthsMaximumStock={sendMonthsMaximumStock}
                        sendMonthsHistory={sendMonthsHistory}
                        sendOption={sendOption}
                        sendCheck={sendCheck}
                    />
                </DialogContent>
                <DialogActions className='p-2'>
                    <button disabled={materialModal.length > 0 && materialModal.length < 6} onClick={updateDataView} className='border-none bg-brand-300 hover:bg-brand-200 disabled:bg-transparent disabled:border-neutral-200 disabled:border disabled:border-solid disabled:text-neutral-200 w-0.75/5 rounded font-semibold py-2'>{t("applyButton")}</button>
                </DialogActions>
            </Dialog>
            {/* Space to show logoCarga if the local state "cargando" is true */}
            {loading ? (
                <Loading text={loadText} />
            ): null}
            {/* Space for the superior bar */}
            <div className="flex items-center flex-row gap-3 2xl:gap-5 ps-2 2xl:ps-4 bg-neutral-500 rounded-xl 4xl:h-0.25/5 h-0.50/5">
                {/* Yellow button filter */}
                <div className='flex items-center w-0.50/5'>
                    <button className="w-full h-full border-none rounded font-semibold py-2 bg-brand-200 hover:bg-brand-300" onClick={openModal}>{t("policyManagement.filterButton")}</button>
                </div>

                {/* Planners selected row */}
                <div className='flex flex-row items-center max-h-10 max-w-2.25/5 gap-1'>
                    <div className="content-center">
                        <h6 className="mb-0 text-neutral-200 text-xs xl:text-base font-semibold">{t("policyManagement.planners")}</h6>
                    </div>
                    <div className='flex flex-wrap gap-1 max-w-4.25/5 2xl:max-w-4.5/5 h-9 items-center bar-element-policy overflow-y-auto'>
                        {plannersSelected.map((item) => (
                            <button key={item} onClick={() => filterTableByPlanners(item)} 
                            className={`flex items-center justify-center text-center w-8 xl:w-10 h-7 xl:h-8 rounded ${filterPlanners.includes(item) ? 'bg-black text-brand-300' : 'bg-neutral-100 text-neutral-500 hover:bg-brand-200'}`}>
                                <p className='mb-0 text-xs xl:text-base'>{item}</p>
                            </button>
                        ))}
                    </div>
                </div>

                {/* Plants selected row */}
                <div className='flex flex-row items-center max-h-10 max-w-2.25/5 gap-1'>
                    <div className="content-center">
                        <h6 className="mb-0 text-neutral-200 text-xs xl:text-base font-semibold">{t("policyManagement.plants")}</h6>
                    </div>
                    <div className='flex flex-wrap gap-1 max-w-4.25/5 2xl:max-w-4.5/5 h-9 items-center bar-element-policy overflow-y-auto'>
                        {buttonsArmed.map((item) => (
                            <button key={item.plant} onClick={() => filterTableByPlants(item.plant)} 
                            className={`flex items-center justify-center text-center w-10 xl:w-14 h-7 xl:h-8 rounded ${item.buttonClass} ${item.marginButton}`}>
                                <p className='mb-0 text-xs xl:text-base'>{item.plant}</p>
                            </button>
                        ))}
                    </div>
                </div>
            </div>
            {/* Space for the tables and barchart */}
            <div className='flex flex-row w-full 4xl:h-4.75/5 h-4.5/5'>
                {/* Space for the tables */}
                <div className='flex flex-row gap-1 w-full'>
                    <div className='w-3/4'>
                        <TablePolicy filteredPolicy={filteredDataPolicy} 
                            filteredDemands={filteredDataDemands} 
                            allDataDemands={newDemandsDataTable}
                            restartScroll={restartScroll}
                            sendChecked={sendChecked}
                            sendCheckeds={sendCheckeds}
                            sendTextFilter={sendTextFilter}
                            sendActiveTable={sendActiveTable}
                            sendRestarScroll={sendRestarScroll}
                            sendLoadDemandsModal={sendLoadDemandsModal}
                            ></TablePolicy>
                    </div>
                    {/* Space for the barchart and buttons*/}
                    <div className='flex flex-col w-1/4 gap-y-1'>
                        <ChartPolicy reorderPointOne={reorderPointOne} 
                                reorderPointTwo={reorderPointTwo} 
                                stockMaxOne={stockMaxOne} 
                                stockMaxTwo={stockMaxTwo}
                                totalOne={totalOne}
                                totalTwo={totalTwo}></ChartPolicy>
                        {/* Space for the download and approve buttons */}
                        <div className='items-center w-full h-0.25/5 flex flex-row justify-center gap-1'>
                            <button onClick={handleExportClick} className={`${role === "user" ? "w-full" : "w-1/2"} flex justify-center items-center text-xs xl:text-sm 2xl:text-base 4xl:text-xl h-full border-none rounded font-semibold px-2 bg-brand-200 hover:bg-brand-300`}>{t("policyManagement.downloadButton")}</button>
                            {role !== "user" ? (
                                <button onClick={handleExportApprove} className='flex justify-center items-center text-xs xl:text-sm 2xl:text-base 4xl:text-xl w-1/2 h-full border-none rounded font-semibold px-2 bg-brand-200 hover:bg-brand-300'>{t("policyManagement.approveButton")}</button>
                            ):null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PolicyComponent