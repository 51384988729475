// Import the resources
import React, { useState, useEffect, useContext } from 'react';
import { CurrencyContext } from '../../../context/CurrencyContext';
import { useNavigate } from 'react-router-dom';
import { LinearProgress } from '@mui/material';
import { formatCurrency, formatNewNumber, removeAllCookies } from '../../../models/generics';
import Loading from '../../pure/loading';
import TableSummary from '../../pure/tableSummary';
import dollar from '../../../images/dollar.png';
import security from '../../../images/security.png';
import door from '../../../images/door.png';
import box from '../../../images/box.png';
import Swal from 'sweetalert2';
import { getSummary } from '../../../models/summary_data';
import { CountryComponentContext } from '../../../context/CountryComponentContext';
import { ZoneContext } from '../../../context/ZoneContext';
import { addSummaries } from '../../../redux/summarySlice';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

/**
 * @component
 * @description This component show the summary report view
 */
const Summary = () => {
  /**
   * @hook
   * @type {function}
   * @description Hook to make the translator using the en.js and es.js file
   */
  const { t } = useTranslation();

  /**
   * @hook
   * @name dispatch
   * @type {function}
   * @description It provides the ability save and get data in the redux store
   */
  const dispatch = useDispatch();

  /**
   * @hook
   * @name summary
   * @type {function}
   * @description It provides the global data in the redux store
   */
  const summary = useSelector((state) => state.summary);

  const { currency, locale, changeRateSelected } = useContext(CurrencyContext);

  /**
   * @context
   * @description Access to get the global state of the CountryComponentContext
   */
  const { country } = useContext(CountryComponentContext);

  /**
   * @context
   * @description Access to get the global state of the ZoneContext
   */
  const { zone } = useContext(ZoneContext);

  /**
   * @const
   * @description Const to save the text to show while the view is loading
   */
  const loadText = t('sumamry.cargaText');

  /**
   * @hook
   * @name navigate
   * @type {function}
   * @description It provides the ability to the app with the country selected
   */
  const navigate = useNavigate();

  /**
   * @state
   * @type {boolean}
   * @default false
   * @description Local State to show the logoCarga
   */
  const [loading, setLoading] = useState(true);

  /**
   * @state
   * @type {array}
   * @default []
   * @description Local State to save the data to show in the table
   */
  const [summaryData, setSummaryData] = useState([]);

  /**
   * @state
   * @type {number}
   * @default 0
   * @description Local states to save the values of the first four boxes
   */
  const [currentTotalValue, setCurrentTotalValue] = useState(0);
  const [materialRiskValue, setMaterialRiskValue] = useState(0);
  const [opportunityTotalValue, setOpportunityTotalValue] = useState(0);
  const [overstockValue, setOverstockValue] = useState(0);

  /**
   * @state
   * @type {number}
   * @default 0
   * @description Local states to save approved and unapproved values represented in money
   */
  const [agreedValue, setAgreedValue] = useState(0);
  const [disagreedValue, setDisagreedValue] = useState(0);

  /**
   * @state
   * @type {number}
   * @default 0
   * @description Local states to save approved and unapproved values represented in percentage
   */
  const [percentageAgreed, setPercentageAgreed] = useState(0);
  const [percentageDisagreed, setPercentageDisagreed] = useState(0);

  /**
   * @effect
   * @description This effect is executed when the data in summary store is updated.
   * Updates the states:
   * summaryData, currentValue, materialRiskValue, totalOpportunityValue, overstockValue, agreedValue,
   * disaAgreedValue, porcentajeAgreed and porcentajeDisagreed
   */
  useEffect(() => {
    const onHandSum = summary.summaries.reduce((accumulator, item) => accumulator + item.on_hand, 0);
    const riskSum = summary.summaries.reduce((accumulator, item) => accumulator + item.on_risk_materials, 0);
    const opportunitySum = summary.summaries.reduce((accumulator, item) => accumulator + item.potential_reduction, 0);
    const overstockSum = summary.summaries.reduce((accumulator, item) => accumulator + item.overstocked_materials, 0);
    const agreedSum = summary.summaries.reduce((accumulator, item) => accumulator + item.approved_reduction, 0);
    const disagreedSum = summary.summaries.reduce((accumulator, item) => accumulator + item.unapproved_reduction, 0);
    const approveedSkusSum = summary.summaries.reduce((accumulator, item) => accumulator + item.approved_skus, 0);
    const unapproveedSkusSum = summary.summaries.reduce((accumulator, item) => accumulator + item.unapproved_skus, 0);
    const total = approveedSkusSum + unapproveedSkusSum;
    const totalAgreed = (approveedSkusSum / total) * 100;
    const totalDisagreed = (unapproveedSkusSum / total) * 100;
    setSummaryData(summary.summaries);
    setCurrentTotalValue(onHandSum);
    setMaterialRiskValue(riskSum);
    setOpportunityTotalValue(opportunitySum);
    setOverstockValue(overstockSum);
    setAgreedValue(agreedSum);
    setDisagreedValue(disagreedSum);
    setPercentageAgreed(totalAgreed);
    setPercentageDisagreed(totalDisagreed);
    if (summary.summaries.length > 0) {
      setLoading(false);
    }
  }, [summary]);

  /**
   * @effect
   * @description This effect is executed when the component is opened.
   * If summary.summaries quantity is less than 1, execute the get method getSummary.
   * Which data execute the reducer addSummaries
   */
  useEffect(() => {
    if (summary.summaries.length < 1) {
      getSummary(country, zone).then((response) => {
          dispatch(addSummaries(response.responseData));
          setLoading(false);
        }).catch((error) => {
          if (error.response.status === 401) {
            Swal.fire({
              icon: 'error',
              title: t('finishedSessionTitle'),
              text: t('finishedSessionText'),
              confirmButtonColor: '#FFC629',
              confirmButtonText: 'LogIn',
              allowOutsideClick: false,
            }).then(() => {
              removeAllCookies();
              navigate('/');
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: '¡Oops!',
              confirmButtonColor: '#FFC629',
              text: t('ApiFail'),
            });
          }
          setLoading(false);
        });
    }
  }, []);

  return (
    <div className="wallpaper flex flex-col w-4.25/5 xl:w-4.5/5 gap-y-1 p-1">
      {/* Space to show logoCarga if the local state "loading" is true */}
      {loading ? <Loading text={loadText} /> : null}
      {/* Space for the first four boxes */}
      <div className="flex flex-row gap-2 h-0.50/5">
        <div className="px-2 xl:px-4 flex items-center bg-neutral-background rounded-xl w-1/4 gap-1 xl:gap-3">
          <div className="flex justify-center items-center h-9 w-9 2xl:h-11 2xl:w-11 4xl:h-16 4xl:w-16 rounded-full bg-brand-300">
            <img src={dollar} alt="Carga Logo" className="w-3/5" />
          </div>
          <div className="flex flex-col justify-center">
            <div className="flex items-center w-full">
              <p className="mb-0 text-xs xl:text-sm 2xl:text-base 4xl:text-xl font-semibold">
                {t('sumamry.currentValue')}
              </p>
            </div>
            <div className="flex items-center justify-center w-full">
              <div className="w-full">
                <p className="mb-0 text-xs xl:text-base 4xl:text-xl hidden xl:block">
                  {formatCurrency(currentTotalValue, 'standard', currency, locale, changeRateSelected)}
                </p>
                <p className="mb-0 text-xs xl:text-base 4xl:text-xl block xl:hidden">
                  {formatCurrency(currentTotalValue, 'compact', currency, locale, changeRateSelected)}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="px-2 xl:px-4 flex items-center bg-neutral-background rounded-xl w-1/4 gap-1 xl:gap-3">
          <div className="flex justify-center items-center h-9 w-9 2xl:h-11 2xl:w-11 4xl:h-16 4xl:w-16 rounded-full bg-brand-300">
            <img src={security} alt="Carga Logo" className="w-3/5" />
          </div>
          <div className="flex flex-col justify-center">
            <div className="flex items-center w-full">
              <p className="mb-0 text-xs xl:text-sm 2xl:text-base 4xl:text-xl font-semibold">
                {t('sumamry.materialRisk')}
              </p>
            </div>
            <div className="flex items-center justify-center w-full">
              <div className="w-full">
                <p className="mb-0 text-xs xl:text-base 4xl:text-xl hidden xl:block">
                  {formatNewNumber(materialRiskValue, 'standard')}
                </p>
                <p className="mb-0 text-xs xl:text-base 4xl:text-xl block xl:hidden">
                  {formatNewNumber(materialRiskValue, 'compact')}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="px-2 xl:px-4 flex items-center bg-neutral-background rounded-xl w-1/4 gap-1 xl:gap-3">
          <div className="flex justify-center items-center h-9 w-9 2xl:h-11 2xl:w-11 4xl:h-16 4xl:w-16 rounded-full bg-brand-300">
            <img src={door} alt="Carga Logo" className="w-3/5" />
          </div>
          <div className="flex flex-col justify-center">
            <div className="flex items-center w-full">
              <p className="mb-0 text-xs xl:text-sm 2xl:text-base 4xl:text-xl font-semibold">
                {t('sumamry.totalOpportunity')}
              </p>
            </div>
            <div className="flex items-center justify-center w-full">
              <div className="w-full">
                <p className="mb-0 text-xs xl:text-base 4xl:text-xl hidden xl:block">
                  {formatCurrency(opportunityTotalValue, 'standard', currency, locale, changeRateSelected)}
                </p>
                <p className="mb-0 text-xs xl:text-base 4xl:text-xl block xl:hidden">
                  {formatCurrency(opportunityTotalValue, 'compact', currency, locale, changeRateSelected)}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="px-2 xl:px-4 flex items-center bg-neutral-background rounded-xl w-1/4 gap-1 xl:gap-3">
          <div className="flex justify-center items-center h-9 w-9 2xl:h-11 2xl:w-11 4xl:h-16 4xl:w-16 rounded-full bg-brand-300">
            <img src={box} alt="Carga Logo" className="w-3/5" />
          </div>
          <div className="flex flex-col justify-center">
            <div className="flex items-center w-full">
              <p className="mb-0 text-xs xl:text-sm 2xl:text-base 4xl:text-xl font-semibold">
                {t('sumamry.materialOverstock')}
              </p>
            </div>
            <div className="flex items-center justify-center w-full">
              <div className="w-full">
                <p className="mb-0 text-xs xl:text-base 4xl:text-xl hidden xl:block">
                  {formatNewNumber(overstockValue, 'standard')}
                </p>
                <p className="mb-0 text-xs xl:text-base 4xl:text-xl block xl:hidden">
                  {formatNewNumber(overstockValue, 'compact')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Space for bars with percentage values */}
      <div className="flex flex-row justify-between h-0.75/5 2xl:h-0.50/5 w-full mrounded-xl bg-neutral-background rounded-xl">
        <div className="flex flex-col justify-center w-1/2 my-1 2xl:my-0 px-2">
          <div className="items-center flex flex-row justify-center w-full">
            <div className="flex items-center h-3/5">
              <p className="mb-0 font-semibold text-sm xl:text-base 4xl:text-2xl text-center">
                {t('sumamry.approved').toUpperCase()}
              </p>
            </div>
            <div className="w-1 h-3.5/5 bg-neutral-600 mx-2"></div>
            <div className="flex items-center h-3/5">
              <p className="mb-0 font-normal text-sm xl:text-base 4xl:text-2xl text-center">
                {formatCurrency(agreedValue, 'standard', currency, locale, changeRateSelected)}
              </p>
            </div>
          </div>
          <div className="flex flex-row justify-center w-full h-0.75/5">
            <LinearProgress
              variant="determinate"
              classes={{ bar: '!bg-brand-300', root: '!bg-neutral-300' }}
              value={percentageAgreed}
              className="w-full !h-full rounded-lg"
            />
          </div>
          <div className="flex flex-row justify-center w-full">
            <p className="mb-0 text-xs 4xl:text-xl font-semibold italic">
              {percentageAgreed > 0 ? percentageAgreed.toFixed(2) : 0}% {t('sumamry.totalIdentified')}
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-center w-1/2 my-1 2xl:my-0 px-2">
          <div className="items-center flex flex-row justify-center w-full">
            <div className="flex items-center h-3/5">
              <p className="mb-0 font-semibold text-sm xl:text-base 4xl:text-2xl text-center">
                {t('sumamry.unApproved').toUpperCase()}
              </p>
            </div>
            <div className="w-1 h-3.5/5 bg-neutral-600 mx-2"></div>
            <div className="flex items-center h-3/5">
              <p className="mb-0 font-normal text-sm xl:text-base 4xl:text-2xl text-center">
                {formatCurrency(disagreedValue, 'standard', currency, locale, changeRateSelected)}
              </p>
            </div>
          </div>
          <div className="flex flex-row justify-center w-full h-0.75/5">
            <LinearProgress
              variant="determinate"
              classes={{ bar: '!bg-brand-300', root: '!bg-neutral-300' }}
              value={percentageDisagreed}
              className="w-full !h-full rounded-lg"
            />
          </div>
          <div className="flex flex-row justify-center w-full">
            <p className="mb-0 text-xs 4xl:text-xl font-semibold italic">
              {percentageDisagreed > 0 ? percentageDisagreed.toFixed(2) : 0}% {t('sumamry.totalIdentified')}
            </p>
          </div>
        </div>
      </div>
      {/* Space for the table */}
      <div className="flex flex-row justify-center w-full h-3/4 2xl:h-4/5">
        <TableSummary summaryData={summaryData}></TableSummary>
      </div>
    </div>
  );
};

export default Summary;
