import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatCurrency, removeAllCookies } from '../../models/generics';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Loading from './loading';
import { CurrencyContext } from '../../context/CurrencyContext';
import Swal from 'sweetalert2';
import { updateIntercompanies, addIntercompaniesSelected } from '../../redux/saleSlice';
import { addGeneralBenefits } from '../../redux/benefitsSlice';
import { postIntercompanyByPlant } from '../../models/intercompany_data';
import { Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import emptyBox from '../../images/empty.png';
import PropTypes from 'prop-types';

/**
 * @component
 * @description This component show the table in the intercompany sales view
 */
const TableIntercompany = ({ selected, data }) => {
  /**
   * @hook
   * @type {function}
   * @description Hook to make the translator using the en.js and es.js file
   */
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const sales = useSelector((state) => state.sale);

  const { currency, locale, changeRateSelected } = useContext(CurrencyContext);

  const navigate = useNavigate();

  /**
   * @state
   * @type {boolean}
   * @default false
   * @description Local State to know if all rows shown are checked
   */
  const [allChecked, setAllChecked] = useState(false);

  const [loading, setLoading] = useState(false);

  /**
   * @effect
   * @description This effect is executed when the data prop is updated.
   * Updates the state todosMarcados because verify that all elements have the selected field in true or false
   */
  useEffect(() => {
    const arrayDosStr = sales.intercompaniesSelected.map(obj => JSON.stringify(obj));
    const isIncluded = sales.intercompanies.every(obj => arrayDosStr.includes(JSON.stringify(obj)));
    if (isIncluded) {
      setAllChecked(true);
    } else {
      setAllChecked(false);
    }
  }, [sales.intercompanies, sales.intercompaniesSelected]);

  const changeIntercompany = (id, status) => {
    const data = {
      "id": id,
      "application_status": status,
    }
    setLoading(true);
    postIntercompanyByPlant(data).then((res) => {
      dispatch(updateIntercompanies(res.data))
      dispatch(addGeneralBenefits(null))
      setLoading(false);
    }).catch((error) => {
      if (error.response.status === 401) {
        Swal.fire({
          icon: 'error',
          title: t("finishedSessionTitle"),
          text: t("finishedSessionText"),
          confirmButtonColor: '#FFC629',
          confirmButtonText: "LogIn",
          allowOutsideClick: false
        }).then(() => {
          removeAllCookies()
          navigate("/")
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: '¡Oops!',
          confirmButtonColor: '#FFC629',
          text: t("ApiFail")
        })
      }
      setLoading(false);
    })
  }

  /**
   * @function
   * @name handleCheckboxChange
   * @description Function to update the key approved value of the register in the intercompany table
   * @param {object} element - Register selected
   */
  const handleCheckboxChange = (element) => {
    const mainIndex = sales.intercompaniesSelected.findIndex(item => item.id === element.id);
    if (mainIndex !== -1) {
      dispatch(addIntercompaniesSelected(sales.intercompaniesSelected.filter(item => item.id !== element.id)));
    } else {
      let newArray = [...sales.intercompaniesSelected]
      newArray.push(element);
      dispatch(addIntercompaniesSelected(newArray));
    }
  };

  /**
   * @function
   * @name handleAllCheckbox
   * @description Function to update the key approved value in all register in the intercompany table
   */
  const handleAllCheckbox = () => {
    const arrayDosStr = sales.intercompaniesSelected.map(obj => JSON.stringify(obj));
    const isIncluded = sales.intercompanies.every(obj => arrayDosStr.includes(JSON.stringify(obj)));
    if (isIncluded) {
      dispatch(addIntercompaniesSelected([]));
    } else {
      dispatch(addIntercompaniesSelected(sales.intercompanies));
    }
  };

  return (
    <>
      {/* Space for the table */}
      {data.length > 0 ? (
        <>
          {loading ? <Loading text={"Updating the intercompany"} /> : null}
          <table className="w-full">
            <thead className="bg-neutral-background z-50 sticky top-0">
              <tr>
                <th>
                  <p className="mb-0 py-2 text-center text-xs xl:text-sm 3xl:text-base 4xl:text-lg">
                    {t('intercompanySales.table.columns.status')}
                  </p>
                </th>
                {selected !== 'all' ? (
                  <th>
                    <p className="mb-0 py-2 text-center text-xs 2xl:text-base 4xl:text-lg">
                      {t('intercompanySales.table.columns.plantaEntrega')}
                    </p>
                  </th>
                ) : null}
                <th>
                  <p className="mb-0 py-2 text-center text-xs xl:text-sm 3xl:text-base 4xl:text-lg">
                    {t('intercompanySales.table.columns.skuCode')}
                  </p>
                </th>
                <th>
                  <p className="mb-0 py-2 text-center text-xs xl:text-sm 3xl:text-base 4xl:text-lg">
                    {t('intercompanySales.table.columns.description')}
                  </p>
                </th>
                <th>
                  <p className="mb-0 py-2 text-center text-xs xl:text-sm 3xl:text-base 4xl:text-lg">
                    {t('intercompanySales.table.columns.providerPlanner')}
                  </p>
                </th>
                <th>
                  <p className="mb-0 py-2 text-center text-xs xl:text-sm 3xl:text-base 4xl:text-lg">
                    {t('intercompanySales.table.columns.providerCriticality')}
                  </p>
                </th>
                <th>
                  <p className="mb-0 py-2 text-center text-xs xl:text-sm 3xl:text-base 4xl:text-lg">
                    {t('intercompanySales.table.columns.plantaCompra')}
                  </p>
                </th>
                <th>
                  <p className="mb-0 py-2 text-center text-xs xl:text-sm 3xl:text-base 4xl:text-lg">
                    {t('intercompanySales.table.columns.stockMover')}
                  </p>
                </th>
                <th>
                  <p className="mb-0 py-2 text-center text-xs xl:text-sm 3xl:text-base 4xl:text-lg">
                    {t('intercompanySales.table.columns.precioTotal')}
                  </p>
                </th>
                <th>
                  <p className="mb-0 py-2 text-center text-xs xl:text-sm 3xl:text-base 4xl:text-lg">
                    {t('intercompanySales.table.columns.distance')}
                  </p>
                </th>
                {selected === 'all' ? (
                  <th>
                    <div className="flex justify-center w-full">
                      <input
                        data-testid="check-all-intercompany"
                        type="checkbox"
                        className="w-4 h-4 2xl:w-5 2xl:h-5"
                        checked={allChecked}
                        onChange={handleAllCheckbox}
                      />
                    </div>
                  </th>
                ) : null}
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={item.id} className={`${index % 2 === 0 ? 'bg-neutral-background' : 'bg-neutral-200'}`}>
                  <td className='w-40'>
                    <div className='my-3'>
                      <FormControl fullWidth size='small'>
                        <InputLabel id="select-intercompany-label" className='!text-xs xl:!text-sm 2xl:!text-base 4xl:!text-lg'>{t('intercompanySales.status_label')}</InputLabel>
                        <Select data-testid={`intercomany-select-${index}`} value={item.application_status || ""} className='!bg-white !rounded-lg !capitalize !text-xs xl:!text-sm 2xl:!text-base 4xl:!text-lg' labelId="select-intercompany-label" id="select-intercompany" label={t('intercompanySales.status_label')}
                        onChange={(event) => changeIntercompany(item.id, event.target.value)}>
                          <MenuItem value={""}><em>{t("userModule.none")}</em></MenuItem>
                          <MenuItem value="accepted">{t('intercompanySales.status.options.accepted')}</MenuItem>
                          <MenuItem value="rejected">{t('intercompanySales.status.options.rejected')}</MenuItem>
                          <MenuItem value="po_generated">{t('intercompanySales.status.options.po_generated')}</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </td>
                  {selected !== 'all' ? (
                    <td>
                      <p className="py-2 text-xs 2xl:text-base 4xl:text-lg text-center">{item.delivery_plant}</p>
                    </td>
                  ) : null}
                  <td>
                    <p className="py-2 text-xs xl:text-sm 3xl:text-base 4xl:text-lg text-center">{item.material}</p>
                  </td>
                  <td>
                    <p className="py-2 text-xs xl:text-sm 3xl:text-base 4xl:text-lg text-center">{item.description}</p>
                  </td>
                  <td>
                    <p className="py-2 text-xs xl:text-sm 3xl:text-base 4xl:text-lg text-center">
                      {item.provider_planner}
                    </p>
                  </td>
                  <td>
                    <p className="py-2 text-xs xl:text-sm 3xl:text-base 4xl:text-lg text-center">
                      {item.provider_criticality !== null ? 'Yes' : 'No'}
                    </p>
                  </td>
                  <td>
                    <p className="py-2 text-xs xl:text-sm 3xl:text-base 4xl:text-lg text-center">{item.purchase_plant}</p>
                  </td>
                  <td>
                    <p className="py-2 text-xs xl:text-sm 3xl:text-base 4xl:text-lg text-center">{item.stock_to_move}</p>
                  </td>
                  <td>
                    <p className="py-2 text-xs xl:text-sm 3xl:text-base 4xl:text-lg text-center">
                      {formatCurrency(item.unit_price, 'standard', currency, locale, changeRateSelected)}
                    </p>
                  </td>
                  <td>
                    <p className="py-2 text-xs xl:text-sm 3xl:text-base 4xl:text-lg text-center">{item.distance_km} Km</p>
                  </td>
                  {selected === 'all' ? (
                    <td>
                      <div className="flex justify-center w-full">
                        <input
                          data-testid="check-intercompany"
                          type="checkbox"
                          className="w-4 h-4 2xl:w-5 2xl:h-5"
                          onChange={() => handleCheckboxChange(item)}
                          checked={[...sales.intercompaniesSelected].map((selected) => selected.id).includes(item.id)}
                        />
                      </div>
                    </td>
                  ) : null}
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : (
        <div className="flex flex-col justify-center gap-y-1 h-full w-full">
          <div className="flex justify-center items-center w-full">
            <img src={emptyBox} alt="Carga Logo" className="w-1/4" />
          </div>
          <div className="flex flex-row justify-center">
            <p className="text-base 3xl:text-lg 4xl:text-xl mb-0 font-semibold">
              {selected === 'all' ? t('intercompanySales.plantEmpty') : t('intercompanySales.selectEmpty')}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

TableIntercompany.propTypes = {
  selected: PropTypes.string,
  data: PropTypes.array
};

export default TableIntercompany;
