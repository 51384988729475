import axios from 'axios';
import Cookies from 'js-cookie';

/**
 * @constant
 * @description The domain for the API, pointing to the local backend.
 * @type {string}
 */
// const domain = 'http://127.0.0.1:8000'; // Local backend
const domain = process.env.REACT_APP_API_URL;

/**
 * @constant
 * @description The general API param
 * @type {string}
 */
const apiParam = process.env.REACT_APP_API_PARAM;

/**
 * @function getAuthConfig
 * @description Reusable config with token and query params for axios requests.
 * @returns {object} Axios request config
 */
function getAuthConfig() {
  const token = Cookies.get('token');

  if (!token) {
    console.error('❌ No authentication token found!');
    throw new Error('No authentication token found!');
  }

  return {
    headers: {
      Authorization: token.startsWith('Bearer ') ? token : `Bearer ${token}`,
      Accept: 'application/json',
    },
    params: apiParam ? { 'api-v-q': apiParam } : {},
  };
}

/**
 * @function getLogs
 * @description Sends a GET request to fetch API logs.
 * @returns {Promise} Axios promise for the HTTP request.
 */
function getLogs() {
  try {
    const config = getAuthConfig();
    return axios.get(`${domain}/ipa_app/api/logs/`, config).then((res) => {
      if (res.status !== 200) {
        console.error('⚠️ Unexpected status code:', res.status);
        throw new Error(`Unexpected response: ${res.statusText}`);
      }
      console.log('✅ Logs fetched successfully:', res.data);
      return res.data;
    });
  } catch (error) {
    console.error('❌ Error preparing log request:', error);
    return Promise.reject(error);
  }
}

/**
 * @function deleteLogs
 * @description Sends a DELETE request to remove all API logs.
 * Requires `confirm_delete: true` in the request body.
 * @returns {Promise} Axios promise for the HTTP request.
 */
function deleteLogs() {
  try {
    const config = getAuthConfig();

    return axios
      .delete(`${domain}/ipa_app/api/logs/delete/`, {
        ...config,
        data: { confirm_delete: true },
      })
      .then((res) => {
        console.log('🗑️ Logs deleted successfully:', res.status);
        return res.status;
      });
  } catch (error) {
    console.error('❌ Error preparing delete request:', error);
    return Promise.reject(error);
  }
}

export { getLogs, deleteLogs };
