import React, { useContext, useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import { CurrencyContext } from '../../context/CurrencyContext';
import { formatCurrency } from '../../models/generics';
import TooltipBenefits from './tooltipCharts/tooltipBenefits';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import PropTypes from 'prop-types';

const InventoryIntercompanyBenefits = ({allBenefits, months, title, colorCountry}) => {

    const { t } = useTranslation();

    const { currency, locale, changeRateSelected } = useContext(CurrencyContext);

    const tableRef = useRef(null);
    const [emptyColumns, setEmptyColumns] = useState(0);

    const transformData = (data) => {
        const months = Object.keys(data[0].values);
        return months.map((month) => {
          const entry = { month };
          data.forEach((countryData) => {
            entry[countryData.code] = countryData.values[month];
          });
          return entry;
        });
    };

    const chartData = transformData(allBenefits);

    const [tickMaxValue, setTickMaxValue] = useState(0);

    useEffect(() => {
        const maxTickValue = Math.max(...chartData.flatMap(data => 
            allBenefits.map(item => data[item.code] || 0)
        ));
        setTickMaxValue(maxTickValue)
    }, [chartData, allBenefits]);

    useEffect(() => {
        if (tableRef.current) {
          const divWidth = tableRef.current.parentElement.offsetWidth;
          const totalColumns = allBenefits.length + 1;
          const columnWidth = 128;
          const neededColumns = Math.floor(divWidth / columnWidth) - totalColumns;
    
          setEmptyColumns(neededColumns > 0 ? neededColumns : 0);
        }
      }, [allBenefits]);

    const formattedMaxValue = formatCurrency(tickMaxValue, "standard", currency, locale, changeRateSelected);
    const yAxisWidth = Math.max(40, formattedMaxValue.length * 8);

  return (
    <div className="w-full h-4.75/5 flex flex-col gap-y-[2px] 2xl:gap-y-1">
        <div className='h-0.25/5 flex items-center'>
            <p className="m-0 text-sm xl:text-xl font-semibold">{title}</p>
        </div>
        <div className='flex flex-col h-4.75/5 gap-y-1 w-full'>
            <div className="w-full h-[52.5%] overflow-y-auto overflow-x-auto">
                <table className="table-fixed border-collapse w-full" ref={tableRef}>
                    <thead className="bg-neutral-background z-50 sticky top-0">
                        <tr>
                            <th className="w-32 text-left px-2">
                                <p className="mb-0 text-center xl:mx-0 text-xs xl:text-sm 3xl:text-base 4xl:text-lg font-semibold"></p>
                            </th>
                            {allBenefits.map((country, index) => (
                                <th key={index + 1} className="w-36 text-left px-2">
                                    <p className="my-2 text-xs xl:text-sm 3xl:text-base 4xl:text-lg text-center font-semibold">
                                        {t('countryList.' + country.country.toLocaleLowerCase())}
                                    </p>
                                </th>
                            ))}

                            {/* Columnas vacías dinámicas */}
                            {Array.from({ length: emptyColumns }).map((_, index) => (
                                <th key={index + 1} className="w-auto px-2">
                                    <p>&nbsp;</p>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {months.map((item, index) => (
                            <tr key={index + 1} className={`${index % 2 === 0 ? 'bg-neutral-background' : 'bg-neutral-200'}`}>
                                <td>
                                    <p className="mb-0 text-center mx-2 xl:mx-0 text-xs xl:text-sm 3xl:text-base 4xl:text-lg font-semibold">
                                        {t('monthList.' + item.toLocaleLowerCase())}
                                    </p>
                                </td>
                                {allBenefits.map((country, indexCountry) => (
                                    <td key={indexCountry+1}>
                                        <p className="mb-0 text-center mx-2 xl:mx-0 text-xs xl:text-sm 3xl:text-base 4xl:text-lg">
                                            {formatCurrency(country.values[item], 'standard', currency, locale, changeRateSelected)}
                                        </p>
                                    </td>
                                ))}
                                {Array.from({ length: emptyColumns }).map((_, index) => (
                                    <td key={index + 1} className="w-auto px-2">
                                        <p>&nbsp;</p>
                                    </td>
                                ))}
                            </tr>
                        ))}
                        <tr className="bg-neutral-300">
                            <td>
                                <p className="mb-0 text-center mx-2 xl:mx-0 text-xs xl:text-sm 3xl:text-base 4xl:text-lg font-bold">
                                    {t('benefits.total')}
                                </p>
                            </td>
                            {allBenefits.map((country, indexCountry) => {
                                const total = months.reduce((sum, month) => sum + country.values[month], 0);
                                return (
                                    <td key={indexCountry + 1}>
                                        <p className="mb-0 text-center mx-2 xl:mx-0 text-xs xl:text-sm 3xl:text-base 4xl:text-lg font-bold">
                                            {formatCurrency(total, 'standard', currency, locale, changeRateSelected)}
                                        </p>
                                    </td>
                                );
                            })}
                            {Array.from({ length: emptyColumns }).map((_, index) => (
                                <td key={index + 1} className="w-auto px-2">
                                    <p>&nbsp;</p>
                                </td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="w-full h-[47.5%] overflow-y-auto overflow-x-auto bg-white rounded-xl">
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart width={150} height={40} data={chartData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="month" className='text-xs 3xl:text-base 4xl:text-lg' tickFormatter={(value) => t('monthList.' + value.toLocaleLowerCase())} />
                        <YAxis type="number" width={yAxisWidth} tickFormatter={(value) => formatCurrency(value, "standard", currency, locale, changeRateSelected)} className='text-xs' />
                        <Tooltip content={<TooltipBenefits allBenefits={allBenefits} />} />
                        <Legend verticalAlign="top" formatter={(value) => {
                            const country = allBenefits.find((item) => item.code === value);
                            return country ? t('countryList.' + country.country.toLowerCase()) : value;
                        }} />
                        {allBenefits.map((item) => (
                            <Bar 
                                key={item.code} 
                                dataKey={item.code}
                                stackId="a"
                                fill={colorCountry.find((colorItem) => colorItem.country === item.code)?.color || "#d9d9d9"}
                                barSize={50}
                            />
                        ))}
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    </div>
  )
}

InventoryIntercompanyBenefits.propTypes = {
    allBenefits: PropTypes.array, 
    months: PropTypes.array, 
    title: PropTypes.string,
    colorCountry: PropTypes.array,
}

export default InventoryIntercompanyBenefits