// Import the resources
import React, {useState, useEffect, useRef} from 'react'
import { Select as SelectUI, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import PropTypes from 'prop-types';

/**
 * @component
 * @description This component show the modal of the spend tracking view
*/
const ModalNegotiation = ({countries, years, months, imputationLevel, vendors, categories, initialYear, initialMonth, initialCountry, initialImputationLevel, initialVendors, initialCategories, sendNewModalData}) => {
  /**
   * @hook
   * @type {function}
   * @description Hook to make the translator using the en.js and es.js file
  */
  const { t } = useTranslation();
  
  /**
   * @state
   * @type {array}
   * @default initialVendors
   * @description Local State to save the vendors selected
  */
  const [vendorSelected, setVendorSelected] = useState(initialVendors.map((vendor) => ({ value: vendor, label: vendor })));

  /**
   * @state
   * @type {array}
   * @default initialCategories
   * @description Local State to save the categories selected
  */
  const [categorySelected, setCategorySelected] = useState(initialCategories.map((category) => ({ value: category, label: category })));
  /**
   * @state
   * @type {array}
   * @default initialImputationLevel
   * @description Local State to save the imputation levels selected
  */
  const [impuntationSelected, setImpuntationSelected] = useState(initialImputationLevel);

  /**
   * @state
   * @type {boolean}
   * @default false
   * @description Local State to know if all imputation levels are selected
  */
  const [allImputationSelected, setAllImputationSelected] = useState(false);

  /**
   * @function
   * @name handleRemoveImputation
   * @description Function to remove or add imputation levels and update the impuntationSelected state
   * @param itemToRemove - Imputation level to remove
  */
  const handleRemoveImputation = (itemToRemove) => {
    if (impuntationSelected.includes(itemToRemove)) {
      if (impuntationSelected.length > 1) {
        setImpuntationSelected(impuntationSelected.filter(item => item !== itemToRemove));
      }
    } else {
      const updatedInitialImputation = [...impuntationSelected, itemToRemove];
      setImpuntationSelected(updatedInitialImputation)
    }      
  };

  /**
   * @function
   * @name handleAllImputation
   * @description Function to select all or anyone imputation level.
   * @param event - Object with event.target.checked on true or false
  */
  const handleAllImputation = (event) => {
    if (event.target.checked) {
      setImpuntationSelected(imputationLevel)
    } else {
      setImpuntationSelected([imputationLevel[0]])
    }
  };

  /**
   * @effect
   * @description This effect is executed when the impuntationSelected state is updated.
   * Check if we have all imputation levels selected and update the allImputationSelected
  */
  useEffect(() => {
    const allSelected = impuntationSelected.length === imputationLevel.length;
    setAllImputationSelected(allSelected)
  }, [impuntationSelected]);

  /**
   * @function
   * @name handleSelectVendor
   * @description Function to save all vendors selected in the multiselect
   * @param selectedList - Array with all elements to save in the vendorSelected state.
  */
  const handleSelectVendor = (selectedList) => {
    setVendorSelected(selectedList);
  };

  /**
   * @function
   * @name handleSelectCategories
   * @description Function to save all categories selected in the multiselect
   * @param selectedList - Array with all elements to save in the categorySelected state.
  */
  const handleSelectCategories = (selectedList) => {
    setCategorySelected(selectedList);
  };

  /**
   * @state
   * @type {string}
   * @default initialCountry
   * @description Local State to save the option selected in country dropdown
  */
  const [selectedCountryOption, setSelectedCountryOption] = useState(initialCountry);

  /**
   * @function
   * @name handleCountrySelect
   * @description Function to save the country option selected
   * @param option - Option selected in country dropdown
  */
  const handleCountrySelect = (option) => {
    setSelectedCountryOption(option);
  };

  /**
   * @state
   * @type {string}
   * @default initialYear
   * @description Local State to save the option selected in year dropdown
  */
  const [selectedYearOption, setSelectedYearOption] = useState(initialYear);

  /**
   * @function
   * @name handleYearSelect
   * @description Function to save the year option selected
   * @param option - Option selected in year dropdown
  */
  const handleYearSelect = (option) => {
    setSelectedYearOption(option);
  };

  /**
   * @state
   * @type {string}
   * @default initialMonth
   * @description Local State to save the option selected in month dropdown
  */
  const [selectedMonthOption, setSelectedMonthOption] = useState(initialMonth);

  /**
   * @function
   * @name handleMonthSelect
   * @description Function to save the month option selected
   * @param option - Option selected in month dropdown
  */
  const handleMonthSelect = (option) => {
    setSelectedMonthOption(option);
  };

  /**
   * @constant
   * @type {array}
   * @description Contain the vendors for the vendors multiselect
  */
  const vendorsAsOptions = vendors.map((vendor) => ({ value: vendor, label: vendor }));

  /**
   * @constant
   * @type {array}
   * @description Contain the categories for the categories multiselect
  */
  const categoriesAsOptions = categories.map((categories) => ({ value: categories, label: categories }));

  /**
   * @constant
   * @type Object
   * @description The styles for the multiselect
  */
  const customStyles = {
    control: (baseStyles) => ({
      ...baseStyles,
      backgroundColor: 'bg-neutral-400',
      borderRadius: '0.25rem'
    }),
    menu: (baseStyles) => ({
      ...baseStyles,
      backgroundColor: 'bg-neutral-400',
    }),
    option: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: state.isFocused ? '#292929' : '#666666',
      color: '#D9D9D9',
      fontWeight: '600'
    }),
    multiValue: (baseStyles) => ({
      ...baseStyles,
      backgroundColor: '#D9D9D9',
      borderRadius: '0.25rem'
    }),
    noOptionsMessage: (baseStyles) => ({
      ...baseStyles,
      backgroundColor: '#666666',
      color: '#D9D9D9',
      fontWeight: '600'
    }),
    input: (baseStyles) => ({
      ...baseStyles,
      color: '#D9D9D9'
    }),
    clearIndicator: (baseStyles) => ({
      ...baseStyles,
      color: '#D9D9D9'
    }),
  };

  /**
   * @effect
   * @description This effect is executed when:
   * selectedCountryOption, selectedYearOption, selectedMonthOption, impuntationSelected, vendorSelected
   * and categorySelected are updated. 
   * Execute the sendNewModalData with the data to make the post method
  */
  useEffect(() => {
    const data = {
      "countrySelected":selectedCountryOption,
      "yearSelected":selectedYearOption.toString(),
      "monthsSelected":selectedMonthOption,
      "impuntationSelected":impuntationSelected,
      "vendorSelected":vendorSelected.map((item) => item.value),
      "categorySelected":categorySelected.map((item) => item.value)
    }
    sendNewModalData(data)
  }, [selectedCountryOption, selectedYearOption, selectedMonthOption, impuntationSelected, vendorSelected, categorySelected]);

  function validateImputationLevel(level) {
    let text;
    if (level === '0') {
        text = t("spendTracking.null");
    } else if (level === '') {
        text = t("spendTracking.empty");
    } else {
        text = level;
    }
    return text;
  }

  /**
   * @ref
   * @default null
   * @description Component reference to manipulate the scroll
  */
  const scrollRef = useRef(null);

  /**
   * @function
   * @name scrollToBottom
   * @description This function make the scroll to the bottom of the component
  */
  const scrollToBottom = () => {
    const scrollContainer = scrollRef.current;
    const isScrollActive = scrollContainer.scrollHeight > scrollContainer.clientHeight;
    if (isScrollActive) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  };

  /**
   * @function
   * @name handleMenuOpen
   * @description This function execute the scrollToBottom function if the select element has the options opened
  */
  const handleMenuOpen = (isOpen) => {
    if (isOpen) {
      const timer = setTimeout(() => {
        scrollToBottom();
      }, 100);
      return () => clearTimeout(timer);
    }
  };

  return (
    <div ref={scrollRef} className='flex flex-col w-full h-full overflow-y-auto gap-y-2 height-modal-config-nego px-2 py-2'>
      {/* Space for the country dropdown */}
      <div className='flex flex-col gap-y-1'>
        <div className='flex flex-row gap-1 items-center'>
            <div className='small-square'></div>
            <div>
                <p className='mb-0 font-semibold text-neutral-200'>{t("spendTracking.buSelection")}</p>
            </div>
        </div>
        <div>
            <SelectUI value={selectedCountryOption} data-testId="select-country" className='!bg-neutral-400 !text-neutral-200 w-1/2 h-10'>
                {countries.map((item) =>(
                    <MenuItem key={item} value={item} data-testId={"country-option-"+item} classes={{selected: "!bg-neutral-500"}} className='!bg-neutral-400 hover:!bg-neutral-500 !text-neutral-200' onClick={() => handleCountrySelect(item)}>{item}</MenuItem>
                ))}
            </SelectUI>
        </div>
      </div>
      <div className='flex flex-row justify-start gap-5 w-full'>
        {/* Space for the year dropdown */}
        <div className='flex flex-col gap-y-1 w-1/2'>
          <div className='flex flex-row gap-1 items-center'>
              <div className='small-square'></div>
              <div>
                  <p className='mb-0 font-semibold text-neutral-200'>{t("spendTracking.year")}</p>
              </div>
          </div>
          <div>
              <SelectUI value={selectedYearOption} data-testId="select-year" className='!bg-neutral-400 !text-neutral-200 w-full h-10'>
                  {years.map((item) =>(
                      <MenuItem key={item} value={item} classes={{selected: "!bg-neutral-500"}} className='!bg-neutral-400 hover:!bg-neutral-500 !text-neutral-200' onClick={() => handleYearSelect(item)}>{item}</MenuItem>
                  ))}
              </SelectUI>
          </div>
        </div>

        {/* Space for the Month dropdown */}
        <div className='flex flex-col gap-y-1 w-1/2'>
          <div className='flex flex-row gap-1 items-center'>
              <div className='small-square'></div>
              <div>
                  <p className='mb-0 font-semibold text-neutral-200'>{t("spendTracking.months")}</p>
              </div>
          </div>
          <div>
              <SelectUI value={selectedMonthOption} data-testId="select-month" className='!bg-neutral-400 !text-neutral-200 w-full h-10'>
                  {months.map((item) =>(
                      <MenuItem key={item} value={item} classes={{selected: "!bg-neutral-500"}} className='!bg-neutral-400 hover:!bg-neutral-500 !text-neutral-200' onClick={() => handleMonthSelect(item)}>{t("spendTracking."+item.toLowerCase())}</MenuItem>
                  ))}
              </SelectUI>
          </div>
        </div>
      </div>
      {/* Space for the imputation levels */}
      <div className='flex flex-col gap-y-1'>
        <div className='flex flex-row justify-between w-full'>
          <div className='flex flex-row gap-1 items-center'>
              <div className='small-square'></div>
              <div>
                  <p className='mb-0 font-semibold text-neutral-200'>{t("spendTracking.imputationLevel")}</p>
              </div>
          </div>
          <div className='w-1/2 flex justify-end gap-2'>
              <p className='mb-0 font-semibold text-neutral-200'>{t("selectAll")}</p>
              <div>
                  <input data-testId="check-all-imputation" type='checkbox' className='w-4 h-4 xl:w-5 xl:h-5' checked={allImputationSelected} onChange={handleAllImputation}/>
              </div>
          </div>
        </div>
        <div className='flex flex-wrap gap-2'>
            {imputationLevel.map((item) => (
              <button key={item} data-testId={`btn-imputation-${item}`} onClick={() => handleRemoveImputation(item)} className={`rounded w-0.50/5 xl:w-0.75/5 4xl:w-0.50/5 font-semibold ${impuntationSelected.includes(item) ? 'bg-brand-300 text-neutral-500' : 'bg-neutral-500 text-neutral-200 hover:bg-brand-200 hover:text-neutral-500'}`}>
                <p className='mb-0 text-center'>{validateImputationLevel(item)}</p>
              </button>
            ))}
        </div>
      </div>
      {/* Space for the vendors multiselect */}
      <div className='flex flex-col w-full gap-y-1'>
        <div className='flex flex-row gap-1 items-center'>
            <div className='small-square'></div>
            <div>
                <p className='mb-0 font-semibold text-neutral-200'>{t("spendTracking.vendor")}</p>
            </div>
        </div>
        <div data-testId="vendor-select">
            <Select
              isMulti
              closeMenuOnSelect={false}
              options={vendorsAsOptions}
              onChange={handleSelectVendor}
              onMenuOpen={() => handleMenuOpen(true)}
              onMenuClose={() => handleMenuOpen(false)}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder={t("spendTracking.placeholderSelectUno")}
              value={vendorSelected}
              styles={customStyles}
            />
        </div>
      </div>
      {/* Space for the categories multiselect */}
      <div className='flex flex-col w-full gap-y-1'>
        <div className='flex flex-row gap-1 items-center'>
            <div className='small-square'></div>
            <div>
                <p className='mb-0 font-semibold text-neutral-200'>{t("spendTracking.category")}</p>
            </div>
        </div>
        <div data-testId="category-select">
            <Select
              isMulti
              closeMenuOnSelect={false}
              options={categoriesAsOptions}
              onChange={handleSelectCategories}
              onMenuOpen={() => handleMenuOpen(true)}
              onMenuClose={() => handleMenuOpen(false)}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder={t("spendTracking.placeholderSelectDos")}
              value={categorySelected}
              styles={customStyles}
            />
        </div>
      </div>
    </div>
  )
}

ModalNegotiation.propTypes = {
  countries: PropTypes.array,
  years: PropTypes.array,
  months: PropTypes.array,
  imputationLevel: PropTypes.array,
  vendors: PropTypes.array,
  categories: PropTypes.array, 
  initialYear: PropTypes.number, 
  initialMonth: PropTypes.string, 
  initialCountry: PropTypes.string, 
  initialImputationLevel: PropTypes.array, 
  initialVendors: PropTypes.array, 
  initialCategories: PropTypes.array,
  sendNewModalData: PropTypes.func
}

export default ModalNegotiation
