import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import Loading from '../../pure/loading';
import Swal from 'sweetalert2';
import { removeAllCookies } from '../../../models/generics';
import { getAllBenefits } from '../../../models/benefits';
import { addGeneralBenefits } from '../../../redux/benefitsSlice';
import emptyBox from '../../../images/empty.png'
import InventoryIntercompanyBenefits from '../../pure/InventoryIntercompanyBenefits';
import StrategicBenefits from '../../pure/StrategicBenefits';

const Benefits = () => {

  /**
   * @hook
   * @type {function}
   * @description Hook to make the translator using the en.js and es.js file
   */
  const { t } = useTranslation();

  const loadText = t("benefits.load_text")

  const dispatch = useDispatch()

  const navigate = useNavigate()

  const benefits = useSelector((state) => state.benefits)

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const colorCountry = [
    {"country": "MX", "color": "#f5df02"},
    {"country": "CO", "color": "#95bbd8"},
    {"country": "PE", "color": "#0070c0"},
    {"country": "EC", "color": "#d3a238"},
    {"country": "PA", "color": "#a6a6a6"},
    {"country": "HO", "color": "#010101"},
    {"country": "SA", "color": "#62b175"},
    {"country": "BR", "color": "#fa5858"},
  ]

  const [loading, setLoading] = useState(true);
  const [tabSelected, setTabSelected] = useState('Inventory');
  const [allBenefits, setAllBenefits] = useState([]);

  const category = t('benefits.tabs.'+tabSelected.toLocaleLowerCase())

  const initialGetBenefits = () => {
    getAllBenefits().then((res) => {
      dispatch(addGeneralBenefits(res))
      setLoading(false)
    }).catch((error) => {
      if (error.response.status === 401) {
          Swal.fire({
              icon: 'error',
              title: t("finishedSessionTitle"),
              text: t("finishedSessionText"),
              confirmButtonColor: '#FFC629',
              confirmButtonText: "LogIn",
              allowOutsideClick: false
          }).then(() => {
              removeAllCookies()
              navigate("/")
          })
      } else {
          Swal.fire({
              icon: 'error',
              title: '¡Oops!',
              confirmButtonColor: '#FFC629',
              text: t("ApiFail")
          })
      }
      setLoading(false)
    })
  }

  useEffect(() => {
    if (benefits.benefitsGeneral === null) {
      initialGetBenefits()
    }
  }, []);

  useEffect(() => {
    if (benefits.benefitsGeneral !== null) {
      const firstKey = Object.keys(benefits.benefitsGeneral)[0];
      const firstValue = benefits.benefitsGeneral[firstKey];
      setTabSelected(firstKey);
      setAllBenefits(firstValue || []);
      setLoading(false)
    }
  }, [benefits.benefitsGeneral]);

  const handleTabOption = (newValue) => {
    setTabSelected(newValue);
    setAllBenefits(benefits.benefitsGeneral[newValue] || [])
  };

  return (
    <div className="wallpaper items-center flex flex-col w-4.25/5 xl:w-4.5/5 h-full 2xl:gap-y-1 p-2">
      {loading ? (
        <Loading text={loadText} />
      ): null}
      <div className="flex flex-row gap-x-2 h-0.25/5 w-full">
        {['Inventory', 'intercompany', 'strategic'].map((item, index) => (
          <Button data-testid={`btn-benefits-${item}`} key={index+1} size='medium' onClick={() => handleTabOption(item)} variant="contained" disabled={tabSelected === item} className={`${tabSelected === item ? "!bg-brand-200" : "!bg-brand-300"} !text-xs xl:!text-sm 2xl:!text-base 4xl:!text-lg !text-black w-1/5 xl:w-0.75/5 4xl:w-0.50/5 !font-semibold !capitalize hover:!bg-brand-200 disabled:!bg-brand-200`}>{t('benefits.tabs.'+item.toLocaleLowerCase())}</Button>
        ))}
      </div>
      {allBenefits.length > 0 ? (
        <>
          {tabSelected === "strategic" ? (
            <StrategicBenefits allBenefits={allBenefits} months={months} title={t('benefits.titleOne')} />
          ) : (
            <InventoryIntercompanyBenefits allBenefits={allBenefits} months={months} title={t('benefits.titleOne')} colorCountry={colorCountry} />
          )}
          
        </>
        
        ) : (
          <div className='flex flex-col justify-center gap-y-1 h-full w-full'>
            <div className='flex justify-center items-center w-full'>
              <img src={emptyBox} alt='Carga Logo' className='w-1/4' />
            </div>
            <div className='flex flex-row justify-center'>
              <p className='text-base 3xl:text-lg 4xl:text-2xl mb-0 font-semibold'>{t("benefits.allEmpty", {category})}</p>
            </div>
          </div>
        )}
    </div>
  );
};

export default Benefits;
