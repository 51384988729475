// Import the resources
import React, { useState, useEffect, useContext } from 'react';
import NavBars from './navBars';
import summaryIcon from '../../images/summaryIcon.svg';
import policyIcon from '../../images/policyIcon.svg';
import blockIcon from '../../images/key.png';
import intercompanyIcon from '../../images/intercompanyIcon.svg';
import negotiationIcon from '../../images/negotiationIcon.svg';
import materialDetailIcon from '../../images/materialDetailIcon.png';
import userIcon from '../../images/user_icon.png';
import anomalyIcon from '../../images/anomaly_icon.png';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { CountryComponentContext } from '../../context/CountryComponentContext';
import { PermissionContext } from '../../context/PermissionContext';
import { RoleContext } from '../../context/RoleContext';
import { useTranslation } from 'react-i18next';

/**
 * @component
 * @description This component show the sidebar to navigate around app.
 */
const SideBar = () => {
  /**
   * @hook
   * @type {function}
   * @description Hook to make the translator using the en.js and es.js file
   */
  const { t } = useTranslation();

  /**
   * @context
   * @description Access to get the global state of the CountryComponentContext
   */
  const { country } = useContext(CountryComponentContext);

  /**
   * @context
   * @description Access to get the global state of the PermissionContext
   */
  const { permissions } = useContext(PermissionContext);

  /**
   * @context
   * @description Access to get the global state of the RoleContext
   */
  const { role } = useContext(RoleContext);

  /**
   * @state
   * @type {boolean}
   * @default false
   * @description Local State to show the option to navigate to the intercompany sales view if the country not is Panana or Honduras.
   */
  const [showIntercompany, setShowIntercompany] = useState(false);

  /**
   * @effect
   * @description This effect is executed when the component is opened.
   * Update the mostrarIntercompany state according with the CountryComponentContext
   */
  useEffect(() => {
    if (
      country.normalCountry === 'PA' ||
      country.normalCountry === 'HO' ||
      permissions.some((item) => item === 'basic') === false
    ) {
      setShowIntercompany(false);
    } else {
      setShowIntercompany(true);
    }
  }, []);

  /**
   * @state
   * @type {string}
   * @default ""
   * @description Local state to save the current path the user is on and mark it in the sidebar
   */
  const [path, setPath] = useState('');

  /**
   * @hook
   * @name navigate
   * @type {function}
   * @description It provides the ability to navigate the app.
   */
  const navigate = useNavigate();

  /**
   * @function
   * @name navigateToRoute
   * @param {string} path - route name
   * @description Navigate to the route specified
   */
  function navigateToRoute(path) {
    navigate(path);
  }

  /**
   * @hook
   * @name location
   * @type {function}
   * @description It provides the complete current route in the app
   */
  const location = useLocation();

  /**
   * @effect
   * @description This effect is executed when location is updated
   * Update the route with the last part of the path the user is on.
   */
  useEffect(() => {
    const pathSegments = location.pathname.split('/');
    const lastSegment = pathSegments[pathSegments.length - 1];
    setPath(lastSegment);
  }, [location.pathname]);

  const optionsRoutes = [
    {
      id: 1,
      title: 'sideBar.summary',
      allowed: permissions.some((item) => item === 'basic'),
      navigateTo: 'summary',
      icon: summaryIcon,
    },
    {
      id: 2,
      title: 'sideBar.policy',
      allowed: permissions.some((item) => item === 'basic'),
      navigateTo: 'policy',
      icon: policyIcon,
    },
    {
      id: 3,
      title: 'sideBar.block',
      allowed: permissions.some((item) => item === 'block'),
      navigateTo: 'block',
      icon: blockIcon,
    },
    {
      id: 4,
      title: 'sideBar.intercompany',
      allowed: showIntercompany,
      navigateTo: 'intercompany',
      icon: intercompanyIcon,
    },
    {
      id: 5,
      title: 'sideBar.spend',
      allowed: permissions.some((item) => item === 'basic'),
      navigateTo: 'negotiation',
      icon: negotiationIcon,
    },
    {
      id: 6,
      title: 'sideBar.material',
      allowed: permissions.some((item) => item === 'basic'),
      navigateTo: 'material',
      icon: materialDetailIcon,
    },
    { id: 7, title: 'sideBar.users', allowed: role === 'super_admin', navigateTo: 'users', icon: userIcon },
    {
      id: 8,
      title: 'sideBar.anomalies',
      allowed: ['basic', 'block', 'approval'].every((perm) => permissions.includes(perm)),
      navigateTo: 'anomalies',
      icon: anomalyIcon,
    },
    {
      id: 9,
      title: 'sideBar.benefits',
      allowed: permissions.some((item) => item === 'basic'),
      navigateTo: 'benefits',
      icon: summaryIcon,
    },
  ];

  return (
    <div className="flex flex-col w-full h-full">
      <NavBars></NavBars>
      <div className="flex flex-row w-full h-4.5/5">
        <div className="w-0.75/5 xl:w-0.50/5 bg-brand-300 flex flex-col gap-y-5 py-2">
          {optionsRoutes.map((item) =>
            item.allowed ? (
              <div key={item.id} className="flex justify-end ps-1 h-10">
                <button
                  onClick={() => navigateToRoute(item.navigateTo)}
                  className={`${path === item.navigateTo ? 'bg-brand-200 font-semibold' : 'hover:bg-brand-200 hover:font-semibold'} h-full w-full flex px-2 gap-1 items-center rounded-s-3xl`}
                >
                  <img className="w-3 h-3 xl:w-4 xl:h-4" src={item.icon} alt="route-icon" />
                  <p className="mb-0 text-xs 4xl:text-base">{t(item.title)}</p>
                </button>
              </div>
            ) : null
          )}
        </div>
        <Outlet />
      </div>
    </div>
  );
};

export default SideBar;
