import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    benefitsGeneral: null,
}


export const benefitsSlice = createSlice({
    name: 'benefits',
    initialState,
    reducers: {
        addGeneralBenefits: (state, action) => {
            state.benefitsGeneral = action.payload;
        },

        resetStateBenefits: () => initialState
    }
})

export const { addGeneralBenefits, resetStateBenefits } = benefitsSlice.actions;
export default benefitsSlice.reducer;