import React, {useState, useEffect} from 'react'
import { IconButton } from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const ModalBlock = ({planners, materialTypes, plants, plannersSelected, plantsSelected, materialSelected, sendNewPlanners, sendNewPlants, sendMaterialsType}) => {

    /**
     * @hook
     * @type {function}
     * @description Hook to make the translator using the en.js and es.js file
    */
    const { t } = useTranslation();

    /**
     * @state
     * @type {array}
     * @default []
     * @description Planners to show if you have more than 12 or less.
    */
    const [plannersToShow, setPlannersToShow] = useState(planners.slice(0, 12));

    /**
     * @state
     * @type {array}
     * @default []
     * @description Plants to show if you have more than 12 or less.
    */
    const [plantsToShow, setPlantsToShow] = useState(plants.slice(0, 12));

    /**
     * @state
     * @type {array}
     * @default []
     * @description Material Types to show if you have more than 12 or less.
    */
    const [materialsToShow, setMaterialsToShow] = useState(materialTypes.slice(0, 12));

    /**
     * @state
     * @type {array}
     * @default plannersSelected
     * @description Local State to save the planners selected
    */
    const [plannersChosen, setPlannersChosen] = useState(plannersSelected);

    /**
     * @state
     * @type {array}
     * @default plantsSelected
     * @description Local State to save the plants selected
    */
    const [plantsChosen, setPlantsChosen] = useState(plantsSelected);

    /**
     * @state
     * @type {array}
     * @default materialSelected
     * @description Local State to save the material types selected
    */
    const [materialsChosen, setMaterialsChosen] = useState(materialSelected);

    /**
     * @state
     * @type {boolean}
     * @default false
     * @description Local States to know if the checkboxes are checked
    */
    const [allPlannerSelected, setAllPlannerSelected] = useState(false);
    const [allPlantSelected, setAllPlantSelected] = useState(false);
    const [allMaterialsSelected, setAllMaterialsSelected] = useState(false);

    const [plusButtonPlanners, setPlusButtonPlanners] = useState(false);
    const [lessButtonPlanners, setLessButtonPlanners] = useState(false);
    const [plusButtonPlants, setPlusButtonPlants] = useState(false);
    const [lessButtonPlants, setLessButtonPlants] = useState(false);
    const [plusButtonMaterials, setPlusButtonMaterials] = useState(false);
    const [lessButtonMaterials, setLessButtonMaterials] = useState(false);

    const [warningPlanners, setWarningPlanners] = useState(true);
    const [warningPlants, setWarningPlants] = useState(true);
    const [warningMaterialType, setWarningMaterialType] = useState(true);

    useEffect(() => {
        sendNewPlanners(plannersChosen)
        sendNewPlants(plantsChosen)
        sendMaterialsType(materialsChosen)

        if (plannersChosen.length > 1) {
            setWarningPlanners(true)
        } else {
            setWarningPlanners(false)
        }

        if (plantsChosen.length > 1) {
            setWarningPlants(true)
        } else {
            setWarningPlants(false)
        }

        if (materialsChosen.length > 1) {
            setWarningMaterialType(true)
        } else {
            setWarningMaterialType(false)
        }
    }, [plannersChosen, plantsChosen, materialsChosen]);

    useEffect(() => {
        if (planners.length > 12) {
            setPlusButtonPlanners(true)
        } else {
            setPlusButtonPlanners(false)
        }

        if (plants.length > 12) {
            setPlusButtonPlants(true)
        } else {
            setPlusButtonPlants(false)
        }

        if (materialTypes.length > 12) {
            setPlusButtonMaterials(true)
        } else {
            setPlusButtonMaterials(false)
        }
    }, []);

    function showAll(category, full) {
        switch (category) {
            case "planners":
                if (full) {
                    setPlannersToShow(planners)
                    setPlusButtonPlanners(false)
                    setLessButtonPlanners(true)
                } else {
                    setPlannersToShow(planners.slice(0, 12))
                    setPlusButtonPlanners(true)
                    setLessButtonPlanners(false)
                }
                break;
            
            case "plants":
                if (full) {
                    setPlantsToShow(plants)
                    setPlusButtonPlants(false)
                    setLessButtonPlants(true)
                } else {
                    setPlantsToShow(plants.slice(0, 12))
                    setPlusButtonPlants(true)
                    setLessButtonPlants(false)
                }
                break;

            case "materials":
                if (full) {
                    setMaterialsToShow(materialTypes)
                    setPlusButtonPlants(false)
                    setLessButtonPlants(true)
                } else {
                    setMaterialsToShow(materialTypes.slice(0, 12))
                    setPlusButtonMaterials(true)
                    setLessButtonMaterials(false)
                }
                break;
        
            default:
                break;
        }
    }

    function acumulator(value, category) {
        let selectedItems;
        let setSelectedItems;

        switch (category) {
            case "planners":
                selectedItems = plannersChosen;
                setSelectedItems = setPlannersChosen;
                break;

            case "plants":
                selectedItems = plantsChosen;
                setSelectedItems = setPlantsChosen;
                break;
            
            case "materials":
                selectedItems = materialsChosen;
                setSelectedItems = setMaterialsChosen;
                break;
        
            default:
                break;
        }

        if (selectedItems.includes(value)) {
            if (selectedItems.length > 1) {
                setSelectedItems(selectedItems.filter(item => item !== value));
            }
        } else {
            setSelectedItems([...selectedItems, value]);
        }
    }

    const handleAllValues = (event, category) => {
        let selectedItems;
        let setSelectedItems;

        switch (category) {
            case "planners":
                selectedItems = planners;
                setSelectedItems = setPlannersChosen;
                break;
            
            case "plants":
                selectedItems = plants;
                setSelectedItems = setPlantsChosen;
                break;
            
            case "materials":
                selectedItems = materialTypes;
                setSelectedItems = setMaterialsChosen;
                break;
        
            default:
                break;
        }

        if (event.target.checked) {
            setSelectedItems(selectedItems.map(item =>item));
        } else {
            setSelectedItems([selectedItems[0]]);
        }
    }

    /**
     * @effect
     * @description This effect is executed when plannersChosen is updated. 
     * Check in true the "Select All" check of the Planners if all planners are selected
    */
    useEffect(() => {
        const allPlannersSelected = plannersChosen.length === planners.length;
        setAllPlannerSelected(allPlannersSelected)

        const allPlantsSelected = plantsChosen.length === plants.length;
        setAllPlantSelected(allPlantsSelected)

        const allMaterialsSelected = materialsChosen.length === materialTypes.length;
        setAllMaterialsSelected(allMaterialsSelected);
    }, [plannersChosen, plantsChosen, materialsChosen]);

    const rowsOptions = [
        {
            "id": 1,
            "title":"policyManagement.planners", 
            "checkedConst": allPlannerSelected,
            "arrayToShow": plannersToShow,
            "categoryToFunc": "planners",
            "arrayElegidos": plannersChosen,
            "plussButton": plusButtonPlanners,
            "lessButton": lessButtonPlanners,
            "warningConst": warningPlanners,
            "warningAdvide": "policyManagement.plannerAdvice"
        },
        {
            "id": 2,
            "title":"policyManagement.plants", 
            "checkedConst": allPlantSelected,
            "arrayToShow": plantsToShow,
            "categoryToFunc": "plants",
            "arrayElegidos": plantsChosen,
            "plussButton": plusButtonPlants,
            "lessButton": lessButtonPlants,
            "warningConst": warningPlants,
            "warningAdvide": "policyManagement.plantAdvice"
        },
        {
            "id": 3,
            "title":"policyManagement.materialType", 
            "checkedConst": allMaterialsSelected,
            "arrayToShow": materialsToShow,
            "categoryToFunc": "materials",
            "arrayElegidos": materialsChosen,
            "plussButton": plusButtonMaterials,
            "lessButton": lessButtonMaterials,
            "warningConst": warningMaterialType,
            "warningAdvide": "policyManagement.materialTypeAdvice"
        }
    ]

    const genericFuction = {
        "acumFunc": acumulator,
        "checkAllFunc": handleAllValues,
        "showAll": showAll
    }

  return (
    <div className='flex flex-row justify-between w-full h-full gap-2'>
        <div className='w-full flex flex-col gap-y-3 px-3 h-full overflow-y-auto height-modal-config'>
            {rowsOptions.map((category) => (
                <div key={category.id} className='flex flex-col gap-y-1'>
                    <div className='flex flex-row justify-between w-full'>
                        <p className='mb-0 text-neutral-200 font-semibold'>{t(category.title)}</p>
                        <div className='w-1/2 flex justify-end gap-2'>
                            <p className='mb-0 text-neutral-200 font-semibold'>{t("selectAll")}</p>
                            <input data-testid={`check-${category.categoryToFunc}`} type='checkbox' className='w-4 h-4 xl:w-5 xl:h-5' checked={category.checkedConst} onChange={(event) => genericFuction.checkAllFunc(event, category.categoryToFunc)}/>
                        </div>
                    </div>
                    <div className='grid grid-cols-12 gap-x-2 gap-y-1'>
                        {category.arrayToShow.map((item) => (
                            <button data-testid={`btn-${category.categoryToFunc}-${item}`} key={item} onClick={() => genericFuction.acumFunc(item, category.categoryToFunc)} className={`rounded font-semibold ${category.arrayElegidos.includes(item) ? 'bg-brand-300 text-neutral-500 disabled:bg-neutral-600 disabled:text-neutral-100' : 'bg-neutral-500 text-neutral-200 hover:bg-brand-200 hover:text-neutral-500 disabled:bg-neutral-600 disabled:text-neutral-100'}`}>
                                <p className='mb-0 text-center'>{item}</p>
                            </button>
                        ))}
                        {category.plussButton ? (
                            <IconButton data-testid={`plus-${category.categoryToFunc}`} onClick={() => genericFuction.showAll(category.categoryToFunc, true)} className='!w-6 !p-0 !bg-brand-300' ><AddCircleOutlineIcon className='w-full h-full' /></IconButton>
                        ):null}

                        {category.lessButton ? (
                            <IconButton data-testid={`less-${category.categoryToFunc}`} onClick={() => genericFuction.showAll(category.categoryToFunc, false)} className='!w-6 !p-0 !bg-brand-300' ><RemoveCircleOutlineIcon className='w-full h-full' /></IconButton>
                        ):null}
                    </div>
                    <p className={`mb-0 text-neutral-200 text-xs 4xl:text-base ${category.warningConst ? 'hidden':''}`}>
                        {t(category.warningAdvide)}
                    </p>
                </div>
            ))}
        </div>
    </div>
  )
}

ModalBlock.propTypes = {
    planners: PropTypes.array, 
    materialTypes: PropTypes.array, 
    plants: PropTypes.array, 
    plannersSelected: PropTypes.array, 
    plantsSelected: PropTypes.array, 
    materialSelected: PropTypes.array, 
    sendNewPlanners: PropTypes.func, 
    sendNewPlants: PropTypes.func, 
    sendMaterialsType: PropTypes.func
}

export default ModalBlock