import React, { useState, useEffect } from 'react';
import { getLogs, deleteLogs } from '../../../models/logs';
import Loading from '../../pure/loading';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Tooltip, Modal, Box, Typography, IconButton } from '@mui/material';
import { ContentCopy, Close } from '@mui/icons-material';
import Swal from 'sweetalert2';

/**
 * @component Logs
 * @description Displays API logs with response status highlighting and deletion functionality.
 */
const Logs = () => {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [selectedError, setSelectedError] = useState('');

  useEffect(() => {
    fetchLogs();
  }, []);

  /**
   * Fetch logs from the API.
   */
  const fetchLogs = () => {
    setLoading(true);
    getLogs()
      .then((response) => {
        if (response && response.results) {
          setLogs(response.results);
        } else {
          console.warn('⚠️ Logs API response does not contain expected data:', response);
        }
      })
      .catch((error) => {
        console.error('❌ Error fetching logs:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to fetch logs. Please try again later.',
          confirmButtonColor: '#FFC629',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /**
   * Handles the deletion of logs with a confirmation prompt.
   */
  const handleDeleteLogs = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This action will permanently delete all logs!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete all logs',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteLogs()
          .then(() => {
            Swal.fire({
              icon: 'success',
              title: 'Logs Deleted',
              text: 'All logs have been successfully deleted.',
              confirmButtonColor: '#3085d6',
            });
            setLogs([]); // Clear logs from state
          })
          .catch((error) => {
            console.error('❌ Error deleting logs:', error);
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Failed to delete logs. Please try again later.',
              confirmButtonColor: '#d33',
            });
          });
      }
    });
  };

  /**
   * Returns the background color based on the response status.
   * @param {number} status - HTTP response status code.
   * @returns {string} Background color for the row.
   */
  const getRowColor = (status) => {
    if (status >= 500) return '#ffcccc'; // Red for server errors
    if (status >= 400) return '#fff3cd'; // Yellow for client errors
    return 'inherit'; // Default color
  };

  /**
   * Handles opening the modal with the selected error message.
   * @param {string} message - The error message to display.
   */
  const handleOpenModal = (message) => {
    setSelectedError(message);
    setOpenModal(true);
  };

  /**
   * Handles closing the modal.
   */
  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedError('');
  };

  /**
   * Copies the error message to the clipboard.
   */
  const copyToClipboard = () => {
    navigator.clipboard.writeText(selectedError);
    Swal.fire({
      icon: 'success',
      title: 'Copied!',
      text: 'Error message copied to clipboard.',
      timer: 1500,
      showConfirmButton: false,
      position: 'top', // Ensure it shows above the modal
      toast: true,
      background: '#333',
      color: '#fff',
    });
  };

  return (
    <div className='wallpaper flex flex-col w-4.25/5 xl:w-4.5/5 gap-y-1 p-1'>
      {loading && <Loading text='Fetching logs...' />}

      <div className='flex justify-between items-center mb-2'>
        <h2 className='text-xl font-semibold text-center'>API Logs</h2>
        <Button variant='contained' color='error' onClick={handleDeleteLogs}>
          Delete Logs
        </Button>
      </div>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Timestamp</TableCell>
              <TableCell>Method</TableCell>
              <TableCell>Path</TableCell>
              <TableCell>User</TableCell>
              <TableCell>IP</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Error Message</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {logs.length > 0 ? (
              logs.map((log) => (
                <TableRow key={log.id} sx={{ backgroundColor: getRowColor(log.response_status) }}>
                  <TableCell>{log.id}</TableCell>
                  <TableCell>{new Date(log.timestamp).toLocaleString()}</TableCell>
                  <TableCell>{log.method}</TableCell>
                  <TableCell>{log.path}</TableCell>
                  <TableCell>{log.user}</TableCell>
                  <TableCell>{log.ip}</TableCell>
                  <TableCell>{log.response_status}</TableCell>
                  <TableCell>
                    {log.message ? (
                      <Tooltip title="Click to view full message" placement="top">
                        <span
                          style={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue' }}
                          onClick={() => handleOpenModal(log.message)}
                        >
                          View
                        </span>
                      </Tooltip>
                    ) : (
                      'No message'
                    )}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={8} className='text-center'>
                  No logs available.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Error Message Modal */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 500,
            bgcolor: 'white',
            boxShadow: 24,
            p: 3,
            borderRadius: '8px',
            maxHeight: '70vh', // Ensure it does not overflow screen height
            overflowY: 'auto', // Enable scrolling for long messages
          }}
        >
          <div className="flex justify-between items-center">
            <Typography variant="h6">Error Message</Typography>
            <IconButton onClick={handleCloseModal}>
              <Close />
            </IconButton>
          </div>
          <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap', maxHeight: '60vh', overflowY: 'auto' }}>
            {selectedError}
          </Typography>
          <Box sx={{ position: 'sticky', bottom: 0, backgroundColor: 'white', p: 2 }}>
            <Button variant="contained" color="primary" startIcon={<ContentCopy />} onClick={copyToClipboard}>
              Copy
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default Logs;
