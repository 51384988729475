import React, {useContext} from 'react'
import { useTranslation } from 'react-i18next';
import { formatCurrency } from '../../../models/generics';
import { CurrencyContext } from '../../../context/CurrencyContext';
import PropTypes from 'prop-types';

const TooltipBenefits = ({ active, payload, label, allBenefits }) => {
    
    const { t } = useTranslation();

    const {currency, locale, changeRateSelected} = useContext(CurrencyContext)

    if (active && payload?.length) {
        return (
            <div className="flex flex-col items-center p-2 rounded-lg bg-white">
                <p className="font-semibold mb-0">{t('monthList.' + label.toLocaleLowerCase())}</p>
                {payload.length > 1 ? (
                    <>
                        {payload.map((item, index) => {
                            const country = allBenefits.find((benefit) => benefit.code === item.dataKey);
                            return (
                                <p key={index+1} className="font-semibold mb-0 text-start">
                                    {country ? t('countryList.' + country.country.toLowerCase()) : item.dataKey}:
                                    {" "} 
                                    {formatCurrency(item.value, "standard", currency, locale, changeRateSelected)}
                                </p>
                            );
                        })}
                    </>
                ) : (
                    <p className="font-semibold mb-0 text-start">{formatCurrency(payload[0].value, "standard", currency, locale, changeRateSelected)}</p>
                )}
            </div>
        );
    }
}

TooltipBenefits.propTypes = {
    active: PropTypes.bool,
    payload: PropTypes.array,
    label: PropTypes.string,
    allBenefits: PropTypes.array
}

export default TooltipBenefits