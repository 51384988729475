import axios from "axios";
import Cookies from 'js-cookie';

/**
 * @constant
 * @description The domain for the API, fetched from environment variables.
 * @type {string}
*/
const domain = process.env.REACT_APP_API_URL

/**
 * @constant
 * @description The general API param
 * @type {string}
*/
const apiParam = process.env.REACT_APP_API_PARAM

/**
 * @function getAllBenefits
 * @description Sends a GET request to the benefits of the API.
 * @returns {Promise} Axios promise for the HTTP request, which resolves to the response data.
*/
function getAllBenefits() {
    const config = {
        headers: {
          'Authorization': Cookies.get('token')
        }
    }
    return axios.get(`${domain}/ipa_app/benefits/?${apiParam}`, config).then(res => {
        const responseData = res.data;
        return responseData;
    });
}

function getBenefitsByCountry(country, category) {
    const config = {
        headers: {
          'Authorization': Cookies.get('token')
        }
    }
    return axios.get(`${domain}/ipa_app/benefits/?country=${country}&category=${category}&${apiParam}`, config).then(res => {
        const responseData = res.data;
        return responseData;
    });
}

export { getAllBenefits, getBenefitsByCountry };