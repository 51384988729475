import React, { useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { CurrencyContext } from '../../context/CurrencyContext';
import { formatCurrency, formatNewNumber } from '../../models/generics';
import TooltipBenefits from './tooltipCharts/tooltipBenefits';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import PropTypes from 'prop-types';

const StrategicBenefits = ({allBenefits, title}) => {
    
    const { t } = useTranslation();

    const { currency, locale, changeRateSelected } = useContext(CurrencyContext);
    const totalImpact = allBenefits.reduce((sum, value) => sum + value.impact, 0)
    const totalCancelled = allBenefits.reduce((sum, value) => sum + value.cancelled_po, 0)

    const [tickMaxValue, setTickMaxValue] = useState(0);

    useEffect(() => {
        const maxTickValue = Math.max(...allBenefits.map((value) => value.impact));
        setTickMaxValue(maxTickValue)
    }, [allBenefits]);

    const formattedMaxValue = formatCurrency(tickMaxValue, "standard", currency, locale, changeRateSelected);
    const yAxisWidth = Math.max(40, formattedMaxValue.length * 8);

  return (
    <div className="w-full h-4.75/5 flex flex-col gap-y-[2px] 2xl:gap-y-1">
        <div className='h-0.25/5 flex items-center'>
            <p className="m-0 text-sm xl:text-xl font-semibold">{title}</p>
        </div>
        <div className='flex flex-col h-4.75/5 gap-y-1 w-full'>
            <div className="w-full h-[52.5%] overflow-y-auto overflow-x-auto">
                <table className="w-full">
                    <thead className="bg-neutral-background z-50 sticky top-0">
                        <tr>
                            <th className="px-2">
                                <p className="mb-0 text-center xl:mx-0 text-xs xl:text-sm 3xl:text-base 4xl:text-lg font-semibold"></p>
                            </th>
                            <th className="px-2">
                                <p className="mb-0 text-center xl:mx-0 text-xs xl:text-sm 3xl:text-base 4xl:text-lg font-semibold">
                                    {t('benefits.table.impact')}
                                </p>
                            </th>
                            <th className="px-2">
                                <p className="mb-0 text-center xl:mx-0 text-xs xl:text-sm 3xl:text-base 4xl:text-lg font-semibold">
                                    {t('benefits.table.cancelled')}
                                </p>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {allBenefits.map((item, index) => (
                            <tr key={index + 1} className={`${index % 2 === 0 ? 'bg-neutral-background' : 'bg-neutral-200'}`}>
                                <td>
                                    <p className="mb-0 text-center mx-2 xl:mx-0 text-xs xl:text-sm 3xl:text-base 4xl:text-lg font-semibold">
                                        {t('monthList.' + item.month.toLocaleLowerCase())}
                                    </p>
                                </td>
                                <td>
                                    <p className="mb-0 text-center mx-2 xl:mx-0 text-xs xl:text-sm 3xl:text-base 4xl:text-lg">
                                    {formatCurrency(item.impact, 'standard', currency, locale, changeRateSelected)}
                                    </p>
                                </td>
                                <td>
                                    <p className="mb-0 text-center mx-2 xl:mx-0 text-xs xl:text-sm 3xl:text-base 4xl:text-lg">
                                        {formatNewNumber(item.cancelled_po, 'standard')}
                                    </p>
                                </td>
                            </tr>
                        ))}
                        <tr className="bg-neutral-300">
                            <td>
                                <p className="mb-0 text-center mx-2 xl:mx-0 text-xs xl:text-sm 3xl:text-base 4xl:text-lg font-bold">
                                    {t('benefits.total')}
                                </p>
                            </td>
                            <td>
                                <p className="mb-0 text-center mx-2 xl:mx-0 text-xs xl:text-sm 3xl:text-base 4xl:text-lg font-bold">
                                    {formatCurrency(totalImpact, 'standard', currency, locale, changeRateSelected)}
                                </p>
                            </td>
                            <td>
                                <p className="mb-0 text-center mx-2 xl:mx-0 text-xs xl:text-sm 3xl:text-base 4xl:text-lg font-bold">
                                    {formatNewNumber(totalCancelled, 'standard')}
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="w-full h-[47.5%] overflow-y-auto overflow-x-auto bg-white rounded-xl">
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart width={150} height={40} data={allBenefits}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="month" className='text-xs 3xl:text-base 4xl:text-lg' tickFormatter={(value) => t('monthList.' + value.toLocaleLowerCase())} />
                        <YAxis type="number" width={yAxisWidth} tickFormatter={(value) => formatCurrency(value, "standard", currency, locale, changeRateSelected)} className='text-xs' />
                        <Tooltip content={<TooltipBenefits allBenefits={[]} />} />
                        <Bar dataKey="impact" fill="#f5df02" />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    </div>
  )
}

StrategicBenefits.propTypes = {
    allBenefits: PropTypes.array, 
    title: PropTypes.string
}

export default StrategicBenefits